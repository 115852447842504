<template>
  <div class="level-remain-dialog">
    <el-dialog
      title="續等資格異動"
      :visible="show"
      width="570px"
      :close-on-click-modal="false"
      @close="$emit('update:show', false)"
    >
      <div>
        <div class="pb-[20px]">
          續等條件：{{ displayRemainCondition }}
        </div>
        <el-select v-model="levelRemain" @change="changeRemain">
          <el-option label="已符合續等資格" :value="true" />
          <el-option label="未符合續等資格" :value="false" />
        </el-select>
        <div class="py-[20px]">
          提醒：若異動「已符合續等資格」為「未符合續等資格」，續等計算將會於下筆訂單完成時觸發。
        </div>
        <div class="btn-container">
          <el-button
            style="width: 140px"
            plain
            @click="$emit('update:show', false)"
          >
            取消
          </el-button>
          <el-button style="width: 140px" type="primary" @click="confirm">
            確定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { useMemberLevel } from '@/use/memberLevel'
import { computed, defineComponent, ref } from 'vue'
import { get } from 'lodash'
import { conditionOptionsConfig } from '@/config/memberLevel'

export default defineComponent({
  name: 'LevelRemainDialog',
  props: ['show', 'isLevelRemainFulfilled', 'levelConfig'],
  setup (props, { emit }) {
    const levelRemain = ref(props.isLevelRemainFulfilled)

    const displayRemainCondition = computed(() => {
      const levelRemainCondition = get(props.levelConfig, 'levelRemainCondition')
      const levelRemainPeriod = get(props.levelConfig, 'levelRemainPeriod')
      const levelRemainThreshold = get(props.levelConfig, 'levelRemainThreshold')

      if (levelRemainCondition === conditionOptionsConfig.periodAmount.value) {
        return `指定期間 ${levelRemainPeriod}月，消費滿 ${levelRemainThreshold} 元，可維持至此會員級別`
      } else if (levelRemainCondition === conditionOptionsConfig.periodCount.value) {
        return `指定期間 ${levelRemainPeriod}月，消費滿 ${levelRemainThreshold} 次，可維持至此會員級別`
      }
      return `單次消費滿 ${levelRemainThreshold} 元，可維持至此會員級別`
    })

    const changeRemain = (e) => {
      levelRemain.value = e
    }

    const confirm = () => {
      emit('update:isLevelRemainFulfilled', levelRemain.value)
      emit('update:show', false)
    }
    return {
      levelRemain,
      changeRemain,
      confirm,
      displayRemainCondition,
    }
  },
})
</script>

<style lang="postcss" scoped>
.btn-container {
  @apply flex justify-end;
}
</style>
