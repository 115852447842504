<template>
  <div>
    <p class="title">堂票使用張數統計</p>
    <el-table :data="displayData">
      <el-table-column label="剩餘可用堂票" prop="remain" align="center" />
      <el-table-column label="總張數" prop="availableTimes" align="center" />
      <el-table-column label="已預扣" prop="bookTimes" align="center" />
    </el-table>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'UseClassTicketRecordStatistic',
  props: {
    record: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const displayData = computed(() => {
      return [{
        remain: get(props.record, 'availableUseTimes'),
        availableTimes: get(props.record, 'availableTimes'),
        bookTimes: get(props.record, 'bookedTimes'),
      }]
    })
    return { displayData }
  },
})

</script>

<style scoped lang="postcss">
.title {
  @apply font-medium text-primary-100 text-normal pb-[10px];
}
</style>
