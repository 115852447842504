<template>
  <div class="history__content">
    <div
      v-for="(item, index, key) in data"
      :key="key"
      class="content__item"
    >
      <div class="item__label font-[12px]">
        <span class="mx-1">{{ item.label }}</span>
        <span v-if="item.tip">{{ item.tip }}</span>
        <Tipinfo v-if="item.tooltip" width="200">
          {{ item.tooltip }}
        </Tipinfo>
      </div>
      <div class="item__value">
        <span v-show="showSymbol(item.label) && item.value && item.value < 0">- </span>
        <span v-if="prefix">{{ prefix }}</span> {{ Math.abs(item.value) }} <span v-if="item.unitLabel"> {{ item.unitLabel }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Tipinfo from '@/components/TipInfo.vue'
export default defineComponent({
  name: 'StatisticBlock',
  components: { Tipinfo },
  props: {
    data: { type: Array, default: () => [] },
    prefix: { type: String, default: '$' },
  },
  setup () {
    const showSymbol = (label) => {
      return ['未實現金額', '總進出差額', '總進出差額(排除過期)'].includes(label)
    }
    return { showSymbol }
  },
})
</script>

<style scoped lang="scss">
.history__content,
.record__content {
  display: flex;
  padding: 3px;
  min-height: 79px;
  background-color: white;
}

.history__content {
  margin-top: 22px;
}

.content__item {
  position: relative;
  flex: 1 1 33%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  &:not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 10%;
    right: -1px;
    width: 2px;
    height: 80%;
    background-color: #DCDFE6;
  }

  .item__label {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #2d271b;
    font-size: 14px;
    line-height: 21px;
  }

  .item__value {
    margin-top: 4px;
    @apply text-primary-100;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
}
</style>
