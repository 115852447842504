import { admin2 } from '@/api/instance'

export const GetCustomFlow = async ({
  shopId, scope,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customFlow`,
    params: {
      scope,
      start,
      limit,
    },
  })
}
export const GetCustomFlowCount = async ({
  shopId,
  scope,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customFlow`,
    params: {
      scope,
    },
  })
}

export const CreateCustomFlow = async ({
  shopId,
  enable,
  scope,
  name,
  nodes,
  defaultNodeId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/customFlow`,
    data: {
      enable,
      scope,
      name,
      nodes,
      defaultNodeId,
    },
  })
}
export const UpdateCustomFlow = async ({
  shopId,
  id,
  enable,
  name,
  defaultNodeId,
  nodeChangeActions,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/customFlow/${id}`,
    data: {
      enable,
      name,
      defaultNodeId,
      nodeChangeActions,
    },
  })
}
export const FindCustomFlow = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customFlow/${id}`,
  })
}
export const CalcCustomFlowNodeCount = async ({
  shopId,
  id,
  nodeId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customFlow/${id}/calculateNodeCount`,
    params: { nodeId },
  })
}

// record
export const UpdateCustomFlowRecordStatus = async ({
  shopId,
  orderId,
  customFlowId,
  scope,
  nodeId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/customFlowRecord/${orderId}`,
    data: {
      customFlowId,
      scope,
      nodeId,
    },
  })
}
