import { admin2 } from '@/api/instance'

export const CheckMemberOpentixBinding = async ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberOpentix/${memberId}/checkIsBind`,
  })
}

// 取得 Opentix 票券列表
export const GetCouponOpentix = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/couponOpentix`,
    params: {
      name,
      start,
      limit,
    },
  })
}

// 取得 Opentix 票券數量
export const GetCouponOpentixCount = async ({
  shopId,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/couponOpentix/count`,
    params: {
      name,
    },
  })
}

// 取得 Opentix 票券
export const FindCouponOpentix = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/couponOpentix/${id}`,
  })
}

// 更新 Opentix 票券
export const UpdateCouponOpentix = async ({
  shopId,
  id,
  name,
  notice,
  sentLimit,
  extraSetting,
  image,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/couponOpentix/${id}`,
    data: {
      name,
      notice,
      sentLimit,
      extraSetting,
      image,
    },
  })
}

// 停用 Opentix 票券
export const DisableCouponOpentix = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/couponOpentix/${id}/disable`,
  })
}

// 新增 Opentix 票券
export const CreateCouponOpentix = async ({
  shopId,
  name,
  notice,
  sentLimit,
  firstSentLimit,
  extraSetting,
  image,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/couponOpentix`,
    data: {
      name,
      notice,
      sentLimit,
      firstSentLimit,
      extraSetting,
      image,
    },
  })
}

// 新增 opentix 串接
export const CreateOpentixClient = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/shopCustomModule/opentix`,
    data: {
      enabled,
      config,
    },
  })
}

// 更新 opentix 串接
export const UpdateOpentixClient = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/shopCustomModule/opentix`,
    data: {
      enabled,
      config,
    },
  })
}

// 既有會員建立 opentix 會籍
export const InitOpentixMember = async ({
  shopId,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/shopCustomModule/opentix/initOpentixMember`,
  })
}
