<template>
  <div v-loading="loading" class="restricted-image-upload">
    <!-- {{ fileId }} -->
    <div v-show="(syncFileId && !canPreview) || !preview">已上傳</div>
    <div v-show="!syncFileId && !preview">未上傳</div>
    <div v-if="!syncFileId" class="upload-btn" @click="open">
      <img src="@/assets/upload_btn.svg" alt="" srcset="">
    </div>
    <div
      v-show="syncFileId && canPreview"
      class="preview-placeholder"
      @click="previewImage"
    >
      <img class="w-full" :src="imgUrl" alt="" srcset="">
    </div>

    <div v-if="syncFileId" class="clear-btn" @click="syncFileId = null">
      移除圖片
    </div>
  </div>
</template>

<script>
import { useFileDialog, useVModel } from '@vueuse/core'
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { UploaduserRestrictedFileFile } from '@/api/file'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { api as viewerApi } from 'v-viewer'
import Compressor from 'compressorjs'

export default defineComponent({
  name: 'RestrictedImageUpload',
  props: {
    fileId: {
      type: String,
    },
    preview: {
      type: Boolean,
      default: true,
    },
    hideUpadte: {
      type: Boolean,
      default: false,
    },
    targetMemberId: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const syncFileId = useVModel(props, 'fileId', emit)
    const { shopId } = useShop()
    const showPreview = ref(false)
    const loading = ref(false)
    const { files, open, reset, onChange } = useFileDialog({
      accept: '.png,.jpg,.jpeg', // Set to accept only image files
      directory: false, // Select directories instead of files if set true
    })

    const canPreview = computed(() => {
      if (!props.preview) return false
      return showPreview.value
    })

    const onClearImage = () => {
      syncFileId.value = ''
    }
    const imgUrl = computed(() => {
      if (!get(files.value, '[0]')) return null
      return URL.createObjectURL(get(files.value, '[0]'))
    })

    const previewImage = () => {
      viewerApi({
        images: [imgUrl.value],
        options: {
          toolbar: false,
          navbar: false,
          title: false,
        },
      })
    }

    watchEffect(async () => {
      const file = get(files.value, '[0]')
      if (file) {
        const cmpFIle = new Compressor(file, {
          quality: 0.75,
          maxHeight: 2000,
          maxWidth: 2000,
          async success (result) {
            console.log('result', result)
            loading.value = true
            const [res, err] = await UploaduserRestrictedFileFile({
              shopId: shopId.value,
              file: result,
              name: get(result, 'name'),
              // memberId: props.targetMemberId,
            })
            loading.value = false
            if (err) {
              window.$message.error(err)
              return
            }
            if (res) {
              syncFileId.value = res.id
              showPreview.value = true
            }
          },
          error () {
            window.$message.error('圖片壓縮失敗')
          },
        })

        console.log(cmpFIle)
      }
    })

    return {
      syncFileId,
      open,
      onClearImage,
      canPreview,
      loading,
      imgUrl,
      previewImage,
    }
  },
})
</script>

  <style scoped lang="postcss">
  .restricted-image-upload {
    @apply flex items-baseline gap-[12px];
  }
  .upload-icon {
    @apply w-[fit-content] cursor-pointer;
  }
  .clear-btn {
    @apply cursor-pointer text-primary-100 underline;
  }
  .upload-btn {
    @apply cursor-pointer;
  }

  .preview-placeholder {
    @apply cursor-pointer;
    @apply flex items-center justify-center;
    @apply w-[90px] h-[90px] rounded-[4px] object-cover overflow-hidden;
  }
  </style>
