<template>
  <div class="profile-tab">
    <PageTitle title="" @btnClick="showEditDialog = true" />

    <div class="section-block">
      <el-form
        ref="form"
        :model="formData"
        label-position="left"
        label-width="180px"
        class="form"
      >
        <el-form-item label="用戶狀態" prop="isBlock">
          <el-switch
            v-model="formData.isBlock"
            active-text="黑名單會員"
            inactive-text="正常會員"
            @change="onChangeBlock"
          />
        </el-form-item>

        <el-form-item label="Line 綁定" prop="memberAuthLine">
          <div class="icon__vertical-align">
            <img
              v-if="member.MemberAuthLine"
              src="@/assets/member/icon-line-auth-yes.svg"
              alt="Has Line auth"
            >
            <img
              v-else
              src="@/assets/member/icon-line-auth-no.svg"
              alt="Without Line auth"
            >
          </div>
        </el-form-item>

        <el-form-item v-if="useMemberOpentix" label="OPENTIX 綁定">
          <div class="icon__vertical-align">
            <img
              v-if="opentixBinded"
              src="@/assets/member/icon-line-auth-yes.svg"
              alt="Has Line auth"
            >
            <img
              v-else
              src="@/assets/member/icon-line-auth-no.svg"
              alt="Without Line auth"
            >
          </div>
        </el-form-item>

        <el-form-item label="登入方式" prop="memberUserAuth">
          <div class="icon__vertical-align">
            <span
              v-if="
                !member.User.AuthLine &&
                  !member.User.AuthFB &&
                  !member.User.AuthPhoneAccount
              "
            >尚未登入</span>
            <img
              v-if="member.User.AuthLine"
              src="@/assets/member/icon-auth-line.svg"
              alt="Line auth icon"
            >
            <img
              v-if="member.User.AuthFB"
              src="@/assets/member/icon-auth-facebook.svg"
              alt="Facebook auth icon"
            >
            <img
              v-if="member.User.AuthPhoneAccount"
              src="@/assets/member/icon-auth-phone.svg"
              alt="Phone Account auth icon"
            >
          </div>
        </el-form-item>

        <el-form-item label="會員級別" prop="level">
          <div class="flex items-center" style="gap: 12px">
            <p v-if="formData.level > 0">
              {{ getDisplayLevel() }}
            </p>
            <p v-else>無等級</p>
            <el-button
              type="text"
              class="underline text-primary-100"
              @click="showMemberLevelDetail = true"
            >
              <span style="font-size: 12px">查看</span>
            </el-button>
          </div>
        </el-form-item>

        <el-form-item v-if="memberPolicyData.configData.enable" label="權益政策">
          {{ displayData.memberPolicyStatus }}
        </el-form-item>

        <el-form-item label="手機號碼" prop="phone">
          <div>
            {{ formData.phone || '尚未綁定' }}
          </div>
        </el-form-item>

        <el-form-item
          v-for="field in baseUserSetting"
          :key="field.key"
          :label="field.name"
          :prop="field.key"
        >
          <span>
            {{ getBaseFormData(field) || '尚未填寫' }}
          </span>
        </el-form-item>
        <el-form-item
          v-for="field in customUserSetting"
          v-show="
            field.type !== 'companyDirectoryFirmTaxId' ||
              (showCompany && field.type === 'companyDirectoryFirmTaxId')
          "
          :key="field.key"
          :label="field.name"
          :prop="field.key"
        >
          <span v-if="!['restrictedImage'].includes(field.type)">
            {{ getCustomFormData(field) || '尚未填寫' }}
          </span>
          <div v-if="field.type === 'restrictedImage'" class="flex items-center" style="gap: 12px">
            <p>{{ getCustomFormData(field) ? '已上傳' : '未上傳' }}</p>
            <el-button v-if="getCustomFormData(field) && useFeatures.viewRestrictedFile" type="text" class="text-primary-100 underline" @click="onViewFile(getCustomFormData(field))">
              <span style="font-size: 12px">查看</span>
            </el-button>
          </div>
        </el-form-item>

        <el-form-item label="建立來源">
          <span>{{ memberOrigin(member.origin, member.originText) }}</span>
        </el-form-item>
        <el-form-item label="建立時間">
          <span>{{ formatDate(member.createdAt, 'YYYY/MM/DD HH:mm:ss') }}</span>
        </el-form-item>
      </el-form>
    </div>
    <MemberProfileEdit
      v-if="showEditDialog"
      :member="formData"
      :levelList="levelList"
      :baseUserSetting="baseUserSetting"
      :customUserSetting="customUserSetting"
      :opentixBinded="opentixBinded"
      :useMemberOpentix="useMemberOpentix"
      @refresh="$emit('update')"
      @close="showEditDialog = false"
    />

    <MemberLevelDetail
      v-if="showMemberLevelDetail"
      :memberId="memberId"
      @close="showMemberLevelDetail = false"
    />
  </div>
</template>

<script>
import MemberProfileEdit from './components/MemberProfileEdit.vue'
import MemberLevelDetail from '@/views/Members/components/MemberLevelDetail/MemberLevelDetail.vue'
import { mapGetters } from 'vuex'
import { UpdateMember } from '@/api/member'
import { SimpleFindCompanyContactMenu } from '@/api/company/contactMenu'
import { genderName } from '@/config/userInfoOptions'
import { extractList } from '@/utils/helper'
import { formatDate } from '@/utils/date'
import { handleMemberOrigins } from '@/utils/member'
import { find, get } from 'lodash'
import { defineComponent, computed, ref, reactive } from 'vue'
import { useCompany } from '@/use/company'
import dayjs from '@/lib/dayjs'
import { GetUserInfoSetting } from '@/api/org/org'
import { CheckMemberOpentixBinding } from '@/api/opentix'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { usePermissions } from '@/use/permissions'
import { GetCustomModule } from '@/api/customModule'
import { GetMemberPolicyConfig } from '@/api/memberPolicy'
import store from '@/store'
import { GetRestrictedFileUrl } from '@/api/file'
import { api as viewerApi } from 'v-viewer'

export default defineComponent({
  name: 'MemberInfoTab',
  components: { MemberProfileEdit, MemberLevelDetail },
  props: {
    member: { type: Object, required: true, default: () => {} },
    levelList: { type: Array, required: true, default: () => [] },
  },
  setup (props) {
    const { useCompanyContactMenu, getShopContactMenuInfo, orgTaxId } =
      useCompany()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const { checkAction } = usePermissions()
    const orgId = computed(() => get(store.state, 'org.org.id'))
    const useFeatures = computed(() => {
      return {
        viewRestrictedFile: checkAction('admin.userRestrictedFile.getDownloadUrl'),
      }
    })

    const opentixBinded = ref(false)
    const showMemberLevelDetail = ref(false)
    const memberPolicyData = reactive({
      configData: {},
      isFirstConfig: false,
    })
    const memberId = computed(() => get(props.member, 'id'))
    const useMemberOpentix = computed(() => checkAction('admin.memberOpentix.page'))
    const displayData = computed(() => {
      const memberPolicyStatus = get(props.member, 'UserInfo.hasAgreedRightPolicy')
      return {
        memberPolicyStatus: memberPolicyStatus ? '已同意' : '未同意',
      }
    })
    const opentixConfigData = ref([])

    const checkMemberOpentixBinding = async () => {
      if (!opentixConfigData.value.length) return
      if (!useMemberOpentix.value) return
      await simpleFetch(CheckMemberOpentixBinding, {
        shopId: shopId.value,
        memberId: get(props.member, 'id'),
      }, (res) => {
        opentixBinded.value = res.isBind
      })
    }

    const getMemberPolicyConfig = async () => {
      if (!checkAction('admin.rightPolicyConfig.singleFind')) return
      const [res, err] = await GetMemberPolicyConfig({ orgId: orgId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      memberPolicyData.configData = res
      if (!res.id) memberPolicyData.isFirstConfig = true
    }

    const getOpentixConfig = async () => {
      await simpleFetch(GetCustomModule, {
        shopId: shopId.value,
        type: 'opentix',
      }, (res) => {
        opentixConfigData.value = res
      })
    }

    const onViewFile = async (fileId) => {
      const [res, err] = await GetRestrictedFileUrl({
        shopId: shopId.value,
        memberId: memberId.value,
        userRestrictedFileId: fileId,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      console.log(res)
      viewerApi({
        images: [res.url],
        options: {
          toolbar: false,
          navbar: false,
          title: false,
        },
      })
    }

    return {
      orgId,
      onViewFile,
      useCompanyContactMenu,
      getShopContactMenuInfo,
      orgTaxId,
      showMemberLevelDetail,
      memberId,
      opentixBinded,
      checkMemberOpentixBinding,
      useMemberOpentix,
      getOpentixConfig,
      checkAction,
      memberPolicyData,
      getMemberPolicyConfig,
      displayData,
      useFeatures,
    }
  },
  data: () => ({
    showEditDialog: false,
    company: [],
    formData: {
      MemberAuthLine: false,
      isBlock: false,
      level: '',
      User: {},
      custom: {}, // 自定義欄位資料
    },
    userInfoSetting: [],
  }),

  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),
    baseUserSetting () {
      return this.userInfoSetting.filter((field) => field.isBase)
    },
    customUserSetting () {
      return this.userInfoSetting.filter((field) => !field.isBase)
    },
    getBaseFormData () {
      return (field) => {
        if (field.type === 'gender') {
          return genderName[this.formData[field.key]]
        }
        if (field.type === 'birthday') {
          return dayjs(this.formData[field.key]).format('YYYY-MM-DD')
        }
        return this.formData[field.key]
      }
    },
    getCustomFormData () {
      const customFormData = this.formData.custom || {}
      return (field) => {
        if (field.type === 'checkbox') {
          const fieldData = get(customFormData, field.key) || []
          if (Array.isArray(fieldData)) return fieldData.join('、')
          if (typeof fieldData === 'string') return fieldData
        }
        if (field.type === 'companyDirectoryFirmTaxId') {
          const taxId = customFormData[field.key]
          const company = find(this.company, { taxId })
          if (!company) return '-'
          return `${company.name} (${taxId})`
        }
        return customFormData[field.key]
      }
    },
    showCompany () {
      return this.useCompanyContactMenu && this.orgTaxId(this.customUserSetting)
    },
  },

  watch: {
    member () {
      console.log('member watch')
      this.syncForm()
    },
  },

  async mounted () {
    if (this.checkAction('admin.shopCustomModule.find')) {
      await this.getOpentixConfig()
      this.checkMemberOpentixBinding()
    }
    await this.refresh()
  },

  methods: {
    formatDate,

    async getCompany () {
      let start = 0
      const limit = 10
      const data = []
      let temp = []
      const [res, err] = await SimpleFindCompanyContactMenu({
        shopId: this.shop,
        start,
        limit,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      start += res.length
      temp = res
      data.push(...res)

      while (temp.length === limit) {
        const [res, err] = await SimpleFindCompanyContactMenu({
          shopId: this.shop,
          start,
          limit,
        })
        if (err) {
          this.$message.error(err)
          return
        }
        start += res.length
        temp = res
        data.push(...res)
      }
      this.company = data
    },
    memberOrigin (origin, originText) {
      return handleMemberOrigins({ origin, originText })
    },
    async refresh () {
      await Promise.allSettled([
        this.getMemberPolicyConfig(),
        this.getUserInfoSetting(),
        this.getShopContactMenuInfo({ shopId: this.shop }),
      ])
      if (this.showCompany) {
        this.getCompany()
      }
      this.syncForm()
    },
    getProp (target, key) {
      if (!target) return '無級別'
      return target[key]
    },

    getDisplayLevel () {
      const curLevel = this.member.level
      if (!curLevel) return '無等級'
      return this.levelList?.find(({ level }) => curLevel === level)
        ?.name
    },

    syncForm () {
      console.log('syncForm')
      this.formData.id = this.member.id
      this.formData.isBlock = this.member.isBlock
      this.formData.level = this.member.level
      this.formData.MemberAuthLine = this.member.MemberAuthLine
      this.formData.User = this.member.User

      this.formData.name = this.member.UserInfo.name
      this.formData.phone = this.member.UserInfo.phone
      this.formData.birthday = this.member.UserInfo.birthday
      this.formData.address = this.member.UserInfo.address
      this.formData.gender = this.member.UserInfo.gender
      this.formData.email = this.member.UserInfo.email
      this.formData.levelExpiredAt = this.member.levelExpiredAt
      this.formData.levelNote = this.member.levelNote
      this.formData.forever = !this.member.levelExpiredAt
      this.formData.isLevelRemainFulfilled = this.member.isLevelRemainFulfilled
      this.tagCheckList = extractList('id', this.member.MTags)
      const customList = Object.keys(this.member.UserInfo.custom)
      if (customList.length) {
        customList.forEach((item) => {
          this.formData.custom = {
            ...this.formData.custom,
            [item]: this.member.UserInfo.custom[item],
          }
        })
      }
    },

    async getUserInfoSetting () {
      const [res, err] = await GetUserInfoSetting({
        orgId: this.orgId,
      })

      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }

      this.userInfoSetting = res.fields
    },
    async onChangeBlock () {
      const { isBlock, id } = this.formData
      await UpdateMember({
        id,
        shopId: this.shop,
        isBlock,
      })
        .then(() => {
          this.$message.success('更新成功')
        })
        .catch((error) => {
          this.$message.error({
            message: error || error.message,
          })
        })
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input {
  @apply h-full;
}
::v-deep .el-tag--info {
  @apply h-full break-all;
  white-space: break-spaces;
}
::v-deep .el-select__tags {
  @apply flex flex-wrap;
}

.bind-phone-btn {
  @apply cursor-pointer text-left;
  @apply absolute right-[-88px] top-[2px];
}

.icon__vertical-align {
  @apply inline-flex align-middle;
  > img {
    @apply mr-1;
  }
}
</style>
