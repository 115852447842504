import { admin2 } from './instance'

// get externalPointConfig
export const GetExternalPointConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/externalPointConfig`,
  })
}

// update externalPointConfig
export const UpdateExternalPointConfig = async ({
  shopId,
  source,
  unitName,
  pointName,
  ShopCustomModuleId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/externalPointConfig`,
    data: {
      source,
      unitName,
      pointName,
      ShopCustomModuleId,
    },
  })
}

// get externalPointWallet
export const GetExternalPointWallet = async ({ shopId, memberId }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/externalPointWallet`,
    data: {
      memberId,
    },
  })
}
