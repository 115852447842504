<template>
  <div>
    <div class="chart-container">
      <div class="divider" />
      <div ref="chartRef" class="chart" />
    </div>
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue'
import { useChart } from '@/use/dashboard'

export default defineComponent({
  name: 'Member360AnalysisChart',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { chartRef, updateChart } = useChart({
      series: [
        {
          name: '服務人數',
          data: [0, 0, 0, 0, 0],
        }],
      chart: {
        width: 420,
        height: 320,
        type: 'radar',
      },
      yaxis: {
        stepSize: 1,
      },
      stroke: {
        width: 0,
      },
      xaxis: {
        categories: ['貢獻度', '消費分散度', '活躍程度', '消費潛力', '消費忠誠度'],
      },
    })

    watch(
      () => props.data,
      () => {
        updateChart({
          series: [
            {
              name: '服務人數',
              data: [
                props.data.metricsData.contributionStar,
                props.data.metricsData.dispersionStar,
                props.data.metricsData.activityStar,
                props.data.metricsData.potentialStar,
                props.data.metricsData.loyaltyStar,
              ],
            },
          ],
        })
      },
    )

    return { chartRef }
  },
})
</script>

<style lang="postcss" scoped>

</style>
