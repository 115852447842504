<template>
  <div v-if="!isEmpty(member)" class="member-profile">
    <PageTitle
      title="會員資訊"
      icon="chevron_left"
      style="padding-bottom: 24px"
      :btn="'同步至POS'"
      :hideBtn1="!showPospalSyncBtn"
      :btnFeature="featureKeys.usePospal"
      :btnLoading="loading.syncPospal"
      @iconClick="$router.go(-1)"
      @btnClick="syncPospal"
    />
    <!-- ANCHOR MemberInfoBlock -->
    <MemberInfoBlock
      :member="member"
      :mTagsList="tags"
      :customTagsList="customTags"
      :reservationStatistic="reservationStatistic"
      :totalPaymentPriceSum="totalPaymentPriceSum"
      :rebateWalletBalance="rebateWalletBalance"
      :pointWalletBalance="pointWalletBalance"
      :pospalWalletBalance="pospalWalletBalance"
      :externalPointWalletBalance="externalPointWalletBalance"
      :useExternalPoint="useExternalPoint"
      :levelList="levelList"
      :usePospal="usePospal"
      @update:customTags="updateMemberTags"
    />

    <!-- 會員個人頁面 -->

    <section>
      <MemberPageTabs>
        <el-tabs
          v-model="activeTab"
          class="member-page-tab"
          type="card"
          @tab-click="tabsClick"
        >
          <!-- 特質雷達圖 -->
          <el-tab-pane
            v-if="useBeautyAIMemberAnalysis"
            label="特質雷達圖"
            name="360Analysis"
          >
            <BeautyAI360Analysis
              :member="member"
            />
          </el-tab-pane>
          <!-- 預約 -->
          <el-tab-pane
            v-if="useAppointmentFeature"
            label="預約狀況"
            name="reservation"
          >
            <ReservationTab
              v-if="activeTab === 'reservation'"
              :member="member"
              @create="refreshReservationStatistic"
            />
          </el-tab-pane>
          <!-- 獎金紀錄 -->
          <!-- <el-tab-pane
            label="獎金紀錄"
            name="monetaryBonusRecord"
          >
            <MonetaryBonusRecordTab
              v-if="activeTab === 'monetaryBonusRecord'"
              :member="member"
            />
          </el-tab-pane> -->
          <!-- 儲值金 -->
          <el-tab-pane
            v-if="
              useWalletPermission &&
                useDepositWalletPermission &&
                useWalletRecordPermission
            "
            label="儲值金"
            name="wallet"
          >
            <WalletTab
              v-if="activeTab === 'wallet'"
              :member="member"
              @updateWallet="findMember"
            />
          </el-tab-pane>
          <!-- 回饋金 -->
          <el-tab-pane v-if="useShopRebate" label="回饋金" name="rebate">
            <RebateTab
              v-if="activeTab === 'rebate'"
              :memberId="memberId"
              :rebateWalletBalance="rebateWalletBalance"
              @updateWallet="findMemberRebateWallet"
            />
          </el-tab-pane>

          <!-- 點數 -->
          <el-tab-pane v-if="useShopPoint" label="點數" name="point">
            <PointTab
              v-if="activeTab === 'point'"
              :memberId="memberId"
              :member="member"
              :pointWalletBalance="pointWalletBalance"
              @updatePoint="findOnePointWallet"
            />
          </el-tab-pane>

          <!-- 堂票 -->
          <el-tab-pane
            v-if="useClassTicketFeature"
            label="堂票紀錄"
            name="classTicket"
          >
            <ClassTicketTab
              v-if="activeTab === 'classTicket'"
              :member="member"
            />
          </el-tab-pane>

          <!-- 銷售紀錄 -->
          <el-tab-pane
            v-if="useSalesRecordFeature"
            label="銷售紀錄"
            name="salesRecord"
          >
            <SalesRecordTab
              v-if="activeTab === 'salesRecord'"
              :member="member"
              :useShopRebate="useShopRebate"
            />
          </el-tab-pane>

          <el-tab-pane
            v-if="useMessageNotification"
            label="訊息提醒"
            name="notify"
          >
            <!-- <ImageCropper v-if="uploadDialog" :image="img" @close="uploadDialog = false"/> -->
            <MessageBlock
              v-for="(block, index) in notifyMessageStack"
              :key="`block-${index}`"
              class="mb-3"
              maxContent="500"
              :model.sync="notifyMessageStack[index]"
              :closeBtn="notifyMessageStack.length > 1"
              @error="onError = true"
              @checked="onError = false"
              @close="removeNotifyMessage(index)"
            />

            <el-button
              v-if="notifyMessageStack.length < 5"
              plain
              icon="el-icon-plus"
              style="
                background: transparent;
                color: #5e5e5e;
                border: solid 2px #dadada;
              "
              @click="addBlock"
            >
              新增
            </el-button>

            <PageFixedFooter
              confirmBtn="發送"
              @cancel="resetMessage"
              @confirm="sendMessage"
            />
          </el-tab-pane>

          <!-- 服務歷程 -->
          <el-tab-pane
            v-if="useMemberHistory"
            label="服務歷程"
            name="memberHistory"
          >
            <MemberHistoryTab
              v-if="activeTab === 'memberHistory'"
              :member="member"
            />
          </el-tab-pane>

          <!-- 修改資訊 -->
          <el-tab-pane label="會員資訊" name="info">
            <MemberInfoTab
              :member="member"
              :levelList="levelList"
              @update="findMember"
            />
          </el-tab-pane>

          <!-- 常用收件地點 -->
          <el-tab-pane
            v-if="useMemberAddress"
            label="常用收件地點"
            name="memberAddress"
          >
            <MemberAddressTab
              v-if="activeTab === 'memberAddress'"
              :memberId="memberId"
            />
          </el-tab-pane>

          <!-- 抽獎紀錄 -->
          <el-tab-pane
            v-if="useMemberGameFeature"
            label="抽獎紀錄"
            name="lotteryHistory"
          >
            <LotteryHistoryTab
              v-if="activeTab === 'lotteryHistory'"
              :memberId="memberId"
            />
          </el-tab-pane>

          <!-- 使用券紀錄 -->
          <el-tab-pane
            v-if="useCouponFeature"
            label="票券紀錄"
            name="couponHistory"
          >
            <CouponHistoryTab
              v-if="activeTab === 'couponHistory'"
              :memberId="memberId"
            />
          </el-tab-pane>
          <!-- 訂單紀錄 -->
          <el-tab-pane
            v-if="useMemberOrder"
            label="訂單紀錄"
            name="orderRecord"
          >
            <OrderRecord
              v-if="activeTab === 'orderRecord'"
              :memberId="memberId"
            />
          </el-tab-pane>
          <!-- 商城訂單 -->
          <el-tab-pane
            v-if="useMemberShopOrder"
            label="商城訂單"
            name="memberShopOrderRecord"
          >
            <MemberShopOrderRecordTab
              v-if="activeTab === 'memberShopOrderRecord'"
              :memberId="memberId"
            />
          </el-tab-pane>
          <!-- 訂閱紀錄 -->
          <el-tab-pane
            v-if="useSubscription"
            label="訂閱紀錄"
            name="subscriptionRecord"
          >
            <MemberSubscriptionRecordTab
              v-if="activeTab === 'subscriptionRecord'"
              :member="member"
            />
          </el-tab-pane>
        </el-tabs>
      </MemberPageTabs>
    </section>
  </div>
</template>

<script>
import MemberPageTabs from '@/components/Tabs/MemberPageTabs'
import { mapGetters } from 'vuex'

import MemberInfoBlock from './components/MemberInfoBlock/MemberInfoBlock.vue'
import MessageBlock from '@/components/Message/MessageBlock.vue'
// api
import {
  FindMember,
  UpdateMember,
  MemberConfig,
  sentLineMessage,
  GetMemberTotalPaymentPriceSum,
  FindOnePointWallet,
} from '@/api/member'
import { FindMemberRebateWallet, FindShopRebate } from '@/api/rebate'
import { GetReservationCountByStatus } from '@/api/reservation'
import {
  FindPospalWallet,
  SyncPospalMember,
  CheckPospalMemberSync,
  GetPospalClientConfig,
} from '@/api/pospal'
import { GetExternalPointWallet } from '@/api/externalPoint'

// Tabs
import MemberInfoTab from './Tab/MemberInfoTab/MemberInfoTab.vue'
import ReservationTab from './Tab/ReservationTab.vue'
import ClassTicketTab from './Tab/ClassTicketTab/ClassTicketTab.vue'
import WalletTab from './Tab/WalletTab.vue'
import MemberHistoryTab from './Tab/MemberHistoryTab.vue'
import SalesRecordTab from './Tab/SalesRecordTab/SalesRecordTab.vue'
import LotteryHistoryTab from './Tab/LotteryHistoryTab.vue'
import CouponHistoryTab from './Tab/CouponHistoryTab.vue'
import MemberAddressTab from './Tab/MemberAddressTab.vue'
import OrderRecord from './Tab/OrderRecord.vue'
import MemberShopOrderRecordTab from './Tab/MemberShopOrderRecordTab.vue'
import MemberSubscriptionRecordTab from './Tab/MemberSubscriptionRecordTab.vue'
import RebateTab from './Tab/RebateTab/RebateTab.vue'
import PointTab from './Tab/PointTab/PointTab.vue'
// import MonetaryBonusRecordTab from './Tab/MonetaryBonusRecordTab/MonetaryBonusRecordTab.vue'

// Utils
import MixinFunc from '@/components/MixinFunc'
import { isEmptyObj } from '@/utils/helper'
import formUtils from '@/utils/form'
import ls from '@/utils/localstorage'
import { defineComponent, computed, ref, reactive } from 'vue'
import { useTagStore } from '../Marketing/components/useTagStore'
import { useShop } from '@/use/shop'
import { useRoute } from 'vue-router/composables'
import { get, isNull, isEmpty, map, omit } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useFetch } from '@/use/fetch'
import { FindShopPoint } from '@/api/shopPoint'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import BeautyAI360Analysis from './Tab/BeautyAI360Analysis/BeautyAI360Analysis.vue'

export default defineComponent({
  name: 'MemberProfile',
  components: {
    MemberPageTabs,
    MemberInfoBlock,
    MessageBlock,
    ReservationTab,
    ClassTicketTab,
    WalletTab,
    MemberInfoTab,
    MemberHistoryTab,
    SalesRecordTab,
    LotteryHistoryTab,
    CouponHistoryTab,
    MemberAddressTab,
    OrderRecord,
    RebateTab,
    MemberShopOrderRecordTab,
    MemberSubscriptionRecordTab,
    PointTab,
    // MonetaryBonusRecordTab,
    PageFixedFooter,
    BeautyAI360Analysis,
  },
  mixins: [MixinFunc],
  // 頁面更新時重新取得資料
  async beforeRouteUpdate ({ params }) {
    this.memberId = params.id
    await this.findMember()
    await this.refreshReservationStatistic()
  },
  setup () {
    const featureKeys = {
      useBeautyAIMemberAnalysis: 'admin.beautyAIMemberAnalysis.page',
      usePospal: 'admin.shopCustomModule.posPalMemberWallet',
      useAppointmentFeature: 'admin.appointmentReservation.page',
      useClassTicketFeature: 'admin.classTicket.page',
      useSalesRecordFeature: 'admin.salesRecord.page',
      useMemberHistory: 'admin.memberHistory.page',
      useMemberAddress: 'adminView.member.findUserInfoMoreAddress',
      useCouponFeature: 'admin.couponRecord.page',
      useMemberGameFeature: 'admin.memberGameRecord.page',
      useWalletPermission: 'admin.member.adminUseWallet',
      useDepositWalletPermission: 'admin.member.depositWallet',
      useWalletRecordPermission: 'admin.member.findWalletRecord',
      useMemberOrder: 'admin.ecommerceOrder.page',
      useMessageNotification: 'admin.member.sentLineMessage',
      // useShopRebate: 'admin.shopCashback.operate',
      useMemberShopOrder: 'adminView.memberStoreOrder.page',
      // useShopPoint: 'admin.shopPoint.page',
      useExternalPoint: 'admin.externalPointWallet.getExternalPoint',
      useSubscription: 'admin.subscriptionOrder.page',
    }

    const { shopId } = useShop()
    const route = useRoute()
    const { checkAction, checkActionList } = usePermissions()
    const { simpleFetch } = useFetch()
    const { tags, fetchTagsOnMounted } = useTagStore()
    const pospalWalletBalance = ref(0)
    const externalPointWalletBalance = ref('-')
    const memberId = computed(() => route.params.id)
    const loading = reactive({
      syncPospal: false,
    })
    const showPospalSyncBtn = ref(false)

    fetchTagsOnMounted()
    const customTags = computed(() => {
      return tags.value.filter((i) => i.uiGroup === '_custom')
    })

    const usePospal = computed(() => {
      return checkAction(featureKeys.usePospal)
    })

    const findShopPoint = async () => {
      const [res, err] = await FindShopPoint({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return null
      }
      return res.pointClientId
    }

    const findPospalWallet = async () => {
      const [res, err] = await FindPospalWallet({
        shopId: shopId.value,
        memberId: memberId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      pospalWalletBalance.value = get(res, 'balance')
    }

    const findExternalPointWallet = async () => {
      const [res, err] = await GetExternalPointWallet({
        shopId: shopId.value,
        memberId: memberId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      const balance = get(res, 'balance')
      externalPointWalletBalance.value = isNull(balance) ? '-' : balance
    }

    const syncPospal = async () => {
      loading.syncPospal = true
      const res = await simpleFetch(SyncPospalMember, {
        shopId: shopId.value,
        memberId: memberId.value,
      })
      const resultMsg = get(res, 'msg')
      if (resultMsg !== 'Success') {
        window.$message.error(`同步失敗：${resultMsg}`)
        loading.syncPospal = false
        return
      }
      if (res === 'error') {
        loading.syncPospal = false
        return
      }
      window.$message.success('同步成功 !')
      await findPospalWallet()
      loading.syncPospal = false
      showPospalSyncBtn.value = false
    }

    const getPospalClientConfig = async () => {
      const [res, err] = await GetPospalClientConfig({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return null
      }
      return res
    }

    const checkPospalMemberSync = async () => {
      let existMember
      await simpleFetch(
        CheckPospalMemberSync,
        { shopId: shopId.value, memberId: memberId.value },
        (res) => {
          if (!get(res, 'result.PosPalServiceMember.customerUid')) {
            showPospalSyncBtn.value = true
            existMember = false
          } else {
            existMember = true
          }
        },
      )

      return existMember
    }

    return {
      tags,
      usePospal,
      customTags,
      featureKeys,
      findPospalWallet,
      findExternalPointWallet,
      pospalWalletBalance,
      checkAction,
      syncPospal,
      loading,
      checkPospalMemberSync,
      showPospalSyncBtn,
      checkActionList,
      externalPointWalletBalance,
      getPospalClientConfig,
      findShopPoint,
    }
  },
  data: () => ({
    memberId: null,
    uploadDialog: false,
    member: {},
    activeTab: 'info',
    give: false,
    use: false,
    shopRebate: {},
    reservationStatistic: {
      total: [0],
      absence: [0],
    },

    levelList: [],

    notifyMessageStack: [
      {
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
      },
    ],
    onError: false,
    rebateWalletBalance: 0,
    totalPaymentPriceSum: 0,
    pointWalletBalance: 0,
  }),

  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),
    tab () {
      return this.$route.params.tab
    },
    mapOrigin () {
      const origins = {
        admin: '會員後台',
        sales: '銷售紀錄',
        memberStore: '會員商城',
      }
      return (key) => origins[key] || '-'
    },
    // memberGender () {
    //   const gender = this.member.UserInfo.gender
    //   if (!gender) return false
    //   return gender === 'male' ? '男' : '女'
    // },
    useBeautyAIMemberAnalysis () {
      return this.checkAction(this.featureKeys.useBeautyAIMemberAnalysis)
    },
    useAppointmentFeature () {
      return this.checkAction(this.featureKeys.useAppointmentFeature)
    },
    useClassTicketFeature () {
      return this.checkAction(this.featureKeys.useClassTicketFeature)
    },
    useSalesRecordFeature () {
      return this.checkAction(this.featureKeys.useSalesRecordFeature)
    },
    useMemberHistory () {
      return this.checkAction(this.featureKeys.useMemberHistory)
    },
    useMemberAddress () {
      return this.checkAction(this.featureKeys.useMemberAddress)
    },
    useCouponFeature () {
      return this.checkAction(this.featureKeys.useCouponFeature)
    },
    useMemberGameFeature () {
      return this.checkAction(this.featureKeys.useMemberGameFeature)
    },
    // useMemberWallet () {
    //   return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.walletRecord.page')
    // },
    useWalletPermission () {
      return this.checkAction(this.featureKeys.useWalletPermission)
    },
    useDepositWalletPermission () {
      return this.checkAction(this.featureKeys.useDepositWalletPermission)
    },
    useWalletRecordPermission () {
      return this.checkAction(this.featureKeys.useWalletRecordPermission)
    },
    useExternalPoint () {
      return this.checkAction(this.featureKeys.useExternalPoint)
    },

    useMemberOrder () {
      return this.checkAction(this.featureKeys.useMemberOrder)
    },
    useMessageNotification () {
      return this.checkAction(this.featureKeys.useMessageNotification)
    },
    useShopRebate () {
      return this.checkActionList(
        [
          'admin.member.findOneCashbackWallet',
          'admin.member.addCashbackWalletRecord',
        ],
        'intersection',
      )
    },
    useMemberShopOrder () {
      return this.checkAction(this.featureKeys.useMemberShopOrder)
    },
    useShopPoint () {
      return this.checkActionList(
        [
          'admin.member.findOnePointWallet',
          'admin.member.addPointWalletRecord',
        ],
        'intersection',
      )
    },
    useSubscription () {
      return this.checkAction(this.featureKeys.useSubscription)
    },
    memberTags () {
      if (isEmptyObj(this.member)) return []
      const tagList = this.member.MTags.concat(this.member.SystemMTag)
      tagList.sort((item) => {
        if (item.isSystem) return -1
        return 1
      })
      return tagList
    },

    // memberAvatar () {
    //   const avatar = this.member.UserInfo.Image
    //   return avatar
    //     ? imgSrc(800, avatar)
    //     : require('@/assets/icon/img-placeholder.png')
    // },
    // countSum () {
    //   return (count) => count.reduce((a, b) => a + b, 0)
    // },
  },
  async mounted () {
    this.memberId = this.$route.params.id
    if (ls.getActiveTab()) this.activeTab = ls.getActiveTab()
    if (this.tab) this.activeTab = this.tab
    await this.findMember()

    if (this.useAppointmentFeature) {
      this.refreshReservationStatistic()
    }
    // if (this.checkAction(this.featureKeys.useShopRebate)) {
    //   await this.findShopRebate()
    //   if (this.useShopRebate) this.findMemberRebateWallet()
    // }
    if (this.useShopRebate) {
      await this.findShopRebate()
      if (get(this.shopRebate, 'id')) this.findMemberRebateWallet()
    }

    if (this.useShopPoint) {
      const shopPointConfig = await this.findShopPoint()
      if (!shopPointConfig) return
      this.findOnePointWallet()
    }

    if (
      this.usePospal &&
      this.checkAction('admin.shopCustomModule.posPalClientConfig')
    ) {
      const configData = await this.getPospalClientConfig()
      if (isNull(configData) || isEmpty(configData)) return
      const synced = await this.checkPospalMemberSync()
      if (synced) this.findPospalWallet()
    }

    if (this.useExternalPoint) {
      this.findExternalPointWallet()
    }

    await Promise.all([
      // this.findMemberRebateWallet(),
      this.getMemberTotalPaymentPriceSum(),
      this.getMemberConfig(),

      // this.refreshReservationStatistic(),
    ])
  },

  methods: {
    isEmpty (obj) {
      return isEmptyObj(obj)
    },

    resetForm (form) {
      formUtils.resetForm(this.$refs[form])
    },

    levelTrans (level) {
      const res = this.levelList.find((item) => item.level === level) || {
        name: '',
      }
      return res.name
    },
    // ANCHOR 取得會員資料 API
    async findMember () {
      try {
        this.member = await FindMember({
          shopId: this.shop,
          id: this.memberId,
        })
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findMemberRebateWallet () {
      const [res, err] = await FindMemberRebateWallet({
        shopId: this.shop,
        memberId: this.memberId,
      })
      if (err) return window.$message.error(err)
      this.rebateWalletBalance = res.balance
    },

    async findOnePointWallet () {
      if (!this.useShopPoint) return
      const [res, err] = await FindOnePointWallet({
        shopId: this.shop,
        memberId: this.memberId,
      })
      if (err) return window.$message.error(err)
      this.pointWalletBalance = res.balance
    },

    // ANCHOR 更新會員標籤
    async updateMemberTags (tags) {
      try {
        await UpdateMember({
          shopId: this.shop,
          id: this.memberId,
          mTags: tags,
        })
        await this.findMember()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得member levelList
    async getMemberConfig () {
      const [res, err] = await MemberConfig({
        shopId: this.shop,
      })

      if (err) {
        console.log(err)
        this.$message.error(err)
        return
      }
      this.levelList = res.levels
    },

    async getMemberTotalPaymentPriceSum () {
      const res = await GetMemberTotalPaymentPriceSum({
        shopId: this.shop,
        id: this.memberId,
      })

      const total = res.totalPaymentPriceSum
      const totalWallet = res.totalWalletAmountSum
      if (total) this.totalPaymentPriceSum = total - totalWallet
    },

    addBlock () {
      if (this.notifyMessageStack.length === 5) return
      this.notifyMessageStack.push({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
      })
    },

    removeNotifyMessage (index) {
      if (this.notifyMessageStack.length === 1) return
      this.notifyMessageStack.splice(index, 1)
    },

    //= > 重置訊息推播
    resetMessage () {
      this.notifyMessageStack = [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
        },
      ]
    },

    //= > 發送個人訊息推播
    async sendMessage () {
      const notifyMessageStack = map(this.notifyMessageStack, (item) =>
        omit(item, ['Image']),
      )
      try {
        if (this.onError) return
        if (
          this.notifyMessageStack.length === 1 &&
          this.notifyMessageStack[0].content === '' &&
          this.notifyMessageStack[0].actionContent === undefined
        ) {
          this.$message.warning('至少需要一則訊息，請輸入內容')
          return
        }
        await sentLineMessage({
          shopId: this.shop,
          id: this.member.id,
          useMulticast: false,
          messages: notifyMessageStack,
        })
        this.$message.success('發送訊息成功 !')
        this.resetMessage()
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async getReservationCountByStatus (statusList) {
      try {
        const promiese = statusList.reduce((pre, cur) => {
          pre.push(
            new Promise((resolve) => {
              GetReservationCountByStatus({
                shopId: this.shop,
                MemberId: this.memberId,
                status: cur || undefined,
              })
                .then((res) => resolve(res.count))
                .catch(() => resolve(NaN))
            }),
          )
          return pre
        }, [])
        return Promise.all(promiese)
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
        return [NaN]
      }
    },
    async refreshReservationStatistic () {
      this.reservationStatistic.total = await this.getReservationCountByStatus([
        'wait',
        'complete',
        'absence',
      ])
      this.reservationStatistic.absence =
        await this.getReservationCountByStatus(['absence'])
    },

    tabsClick ({ name }) {
      ls.setActiveTab(name)
    },
    async findShopRebate () {
      const [res, err] = await FindShopRebate({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.shopRebate = res
    },
  },
})
</script>

<style scoped lang="scss">
.member-profile {
  position: relative;

  section {
    margin-bottom: 17px;
  }
}
</style>

<style lang="scss">
.member-profile .el-tabs--card > .el-tabs__header .el-tabs__item.is-top {
  margin-right: 24px;
}

.add__popover {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.add__popover__checkbox {
  display: flex;
  align-items: center;
  margin: 0;

  .el-checkbox__label {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .el-checkbox__inner {
    border: 1px solid #767676;
  }
}
</style>
