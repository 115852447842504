<template>
  <section class="member-info-block">
    <section style="margin: 0">
      <!-- 會員資訊 -->
      <div class="flex mb-3">
        <div class="user-avatar">
          <img :src="memberAvatar" width="135" height="135">
        </div>
        <div class="user-container">
          <div>
            <!-- 會員統計資訊 -->

            <div class="flex items-center" style="gap: 20px">
              <p class="user-name">
                {{ member.UserInfo.name }}
              </p>
              <p class="underline cursor-pointer text-primary-100 text-sm" @click="show.inviteRecord = true">查看推薦紀錄</p>
            </div>

            <div class="user-statistic">
              <div class="user-info-item">
                <p class="info-label">會員等級： {{ memberLevel }}</p>
                <p class="info-label">
                  到期日：
                  {{ memberLevelExpiredDate }}
                </p>
              </div>
              <div class="user-info-item">
                <p class="info-label">
                  手機號碼： {{ member.UserInfo.phone || '-' }}
                </p>
                <p class="info-label">
                  生日： {{ member.UserInfo.birthday || '-' }}
                </p>
                <p class="info-value" />
              </div>
              <div class="user-info-item">
                <p class="flex items-center info-label">
                  歷史累積消費總金額
                  <el-tooltip placement="right">
                    <div slot="content">
                      歷史累積消費總金額已排除「儲值金付款的消費紀錄」
                    </div>
                    <span
                      class="material-icons"
                      style="font-size: 16px; margin-left: 5px"
                    >
                      help_outline
                    </span>
                  </el-tooltip>
                </p>
                <p class="stat-value">$ {{ totalPaymentPriceSum }}</p>
              </div>
            </div>
          </div>
          <hr>
          <!-- 會員資訊 -->
          <div class="user-statistic">
            <div
              v-for="statistic in displayBalanceStatistic"
              :key="statistic.label"
              class="user-info-item"
            >
              <p class="info-label">{{ statistic.label }}</p>
              <p class="info-value">
                {{ statistic.value }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 會員標籤 -->
      <div class="member-tags">
        <div
          v-for="(list, index) in displayMemberTags"
          :key="index"
          class="member-tags__row"
        >
          <div v-for="tag in list" :key="tag.id">
            <!-- 更多標籤 -->
            <MoreTag
              v-if="tag.id === 'more'"
              :showMoreTag="() => (showMoreTag = true)"
            />
            <!-- ANCHOR 新增標籤 -->
            <el-tag
              v-else-if="tag.id === 'add'"
              type="info"
              class="tag__add"
              disable-transitions
              size="small"
              @click="showSelector.tag = true"
            >
              新增＋
            </el-tag>
            <!-- 基本標籤 -->
            <BaseTag v-else :tag="tag" :close="onRemoveTag" />
          </div>
        </div>
      </div>
    </section>

    <el-dialog
      title="會員標籤"
      :visible="showMoreTag"
      width="600px"
      :close-on-click-modal="true"
      @close="showMoreTag = false"
    >
      <div class="flex gap-2 flex-wrap">
        <div v-for="(tag, index) in currentMemberTags" :key="index">
          <!-- 基本標籤 -->
          <BaseTag :tag="tag" :close="onRemoveTag" />
        </div>
        <el-tag
          type="info"
          class="tag__add"
          disable-transitions
          size="small"
          @click="showSelector.tag = true"
        >
          新增＋
        </el-tag>
      </div>
    </el-dialog>
    <TagSelector
      v-if="showSelector.tag"
      :data="customTagsList"
      :syncData="memberCustomTags"
      @close="showSelector.tag = false"
      @confirm="updateCustomTags"
    />
    <MemberInviteRecord
      v-if="show.inviteRecord"
      :member="member"
      @close="show.inviteRecord = false"
    />
  </section>
</template>

<script>
import { imgSrc } from '@/utils/helper'
// import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { computed, defineComponent, reactive, ref, onMounted } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { chunk, get } from 'lodash'
import BaseTag from './components/BaseTag.vue'
import MoreTag from './components/MoreTag.vue'
import { usePermissions } from '@/use/permissions'
import MemberInviteRecord from '@/views/Members/components/MemberInviteRecord/MemberInviteRecord.vue'
import dayjs from '@/lib/dayjs'
import { useRoute } from 'vue-router/composables'
import TagSelector from '@/components/Select/TagSelector.vue'

export default defineComponent({
  name: 'MemberInfoBlock',
  components: {
    BaseTag,
    MoreTag,
    MemberInviteRecord,
    TagSelector,
  },
  props: {
    member: { type: Object, default: () => ({}) },
    mTagsList: { type: Array, default: () => [] },
    customTagsList: { type: Array, default: () => [] },
    reservationStatistic: { type: Object, default: () => ({}) },
    totalPaymentPriceSum: { type: Number, default: 0 },
    rebateWalletBalance: { type: Number, default: 0 },
    usePospal: { type: Boolean, default: false },
    useExternalPoint: { type: Boolean, default: false },
    pointWalletBalance: { type: Number, default: 0 },
    pospalWalletBalance: { type: Number, default: 0 },
    externalPointWalletBalance: { type: [Number, String], default: '-' },
    levelList: { type: Array, default: () => [] },
  },
  emits: ['update:customTags'],
  setup (props, { emit }) {
    const route = useRoute()
    const { width } = useWindowSize()
    const { checkAction } = usePermissions()
    const show = reactive({
      inviteRecord: false,
    })
    const showSelector = reactive({
      tag: false,
    })
    const mTags = ref([])

    const useMemberWallet = computed(() => {
      return checkAction('admin.member.findOne.wallet')
    })
    const useShopRebate = computed(() => {
      return checkAction('adminView.member.shopCashback')
    })
    const useShopPoint = computed(() => {
      return checkAction('admin.shopPoint.page')
    })

    const memberLevel = computed(() => {
      const curLevel = props.member.level
      if (!curLevel) return '無等級'
      return props.levelList?.find(({ level }) => curLevel === level)
        ?.name
    })

    const memberLevelExpiredDate = computed(() => {
      const curLevel = props.member.level
      if (curLevel === 0) return '-'
      const expiredAt = props.member.levelExpiredAt
      return expiredAt ? dayjs(expiredAt).format('YYYY/MM/DD')
        : '永久'
    })

    const memberCustomTags = computed(() => {
      const tagsSource = props.member?.MTags || []
      return props.customTagsList.filter((i) =>
        tagsSource.some((j) => j.id === i.id),
      )
    })

    const memberSystemTags = computed(() => {
      const tagsSource = props.member?.SystemMTag || []
      return props.mTagsList.filter((i) =>
        tagsSource.some((j) => j.id === i.id),
      )
    })

    const displayBalanceStatistic = computed(() => {
      const fields = [
        {
          label: '預約 / 未到',
          value: `${countSum(props.reservationStatistic.total)} / ${countSum(
            props.reservationStatistic.absence,
          )}`,
        },
      ]
      if (useMemberWallet.value) {
        fields.push({
          label: '儲值金',
          value: `$ ${get(props.member, 'Wallet.balance')}`,
        })
      }
      if (useShopRebate.value) {
        fields.push({
          label: '回饋金',
          value: `$ ${props.rebateWalletBalance}`,
        })
      }
      if (useShopPoint.value) {
        fields.push({ label: '點數', value: `${props.pointWalletBalance} 點` })
      }
      if (props.usePospal) {
        const balance = props.pospalWalletBalance
        fields.push({
          label: '會員餘額',
          value: `$ ${balance}`,
        })
      }

      // TOFIX 權限 / 資料來源
      if (props.useExternalPoint) {
        const balance = props.externalPointWalletBalance
        fields.push({
          label: '第三方點數',
          value: balance === '-' ? balance : `${balance} 點`,
        })
      }
      return fields
    })

    const onOpenAddTagPopover = () => {
      mTags.value = memberCustomTags.value.map((i) => i.id)
    }
    const onHideAddTagPopover = () => {
      const originCustomTags = memberCustomTags.value.map((i) => i.id)
      const originExist = originCustomTags.every((i) => mTags.value.includes(i))
      const modifyExist = mTags.value.every((i) => originCustomTags.includes(i))
      if (originExist && modifyExist) return
      emit('update:customTags', [...mTags.value])
    }
    const updateCustomTags = (activeList) => {
      emit('update:customTags', [...activeList.map((i) => i.id)])
    }
    const onRemoveTag = (tagId) => {
      const mTags = props.member?.MTags || []
      emit(
        'update:customTags',
        mTags.filter((i) => i.id !== tagId).map((i) => i.id),
      )
    }

    const obseveCustomTag = computed({
      get () {
        return mTags.value
      },
      set (tags) {
        mTags.value = tags
      },
    })

    const currentMemberTags = computed(() =>
      memberSystemTags.value.concat(memberCustomTags.value),
    )

    const totalDisplayTagCount = computed(() => {
      const screenWidth = width.value
      return screenWidth > 1200 ? 5 : 3
    })

    const displayMemberTags = computed(() => {
      const mixedTags = currentMemberTags.value.slice(
        0,
        totalDisplayTagCount.value,
      )
      if (currentMemberTags.value.length > totalDisplayTagCount.value) {
        mixedTags.push({ id: 'more', name: '更多' })
      }
      mixedTags.push({ id: 'add', name: '新增' })
      return chunk(mixedTags, 10)
    })

    const moreTags = computed(() => {
      return currentMemberTags.value.slice(
        totalDisplayTagCount.value,
        currentMemberTags.value.length,
      )
    })

    const countSum = (count) => count.reduce((a, b) => a + b, 0) || 0

    onMounted(() => {
      const openInviteRecord = get(route.query, 'openInviteRecord')
      if (openInviteRecord) {
        show.inviteRecord = true
      }
    })

    return {
      currentMemberTags,
      displayMemberTags,
      obseveCustomTag,
      onOpenAddTagPopover,
      onHideAddTagPopover,
      onRemoveTag,
      moreTags,
      displayBalanceStatistic,
      dayjs,
      memberLevel,
      memberLevelExpiredDate,
      show,
      showSelector,
      updateCustomTags,
      memberCustomTags,
    }
  },
  data: () => ({
    showMoreTag: false,
  }),
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    // countSum () {
    //   return (count) => count.reduce((a, b) => a + b, 0) || 0
    // },
    memberAvatar () {
      const avatar = this.member.UserInfo.Image
      return avatar
        ? imgSrc(800, avatar)
        : require('@/assets/icon/img-placeholder.png')
    },
  },
})
</script>

<style lang="scss" scoped>
.member-info-block {
  @apply p-[15px] rounded-sub bg-white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.user-avatar {
  @apply w-[135px] h-[135px];
  flex-shrink: 0;
}

.user-container {
  @apply flex flex-col flex-1 px-5 gap-2;
}

.user-statistic {
  @apply flex gap-5 flex-wrap;
}

.user-name {
  @apply text-[14px] leading-[20px];
}

.user-info-item {
  @apply min-w-[140px];
}

.info-label {
  @apply text-gray-80 text-[12px] leading-[27px];
}

.member-tags {
  flex-grow: 1;
  @apply flex flex-col gap-y-[8px];
}

.member-tags__row {
  @apply flex flex-row flex-nowrap gap-[8px] h-[25px];
}

.no-custom-tag {
  @apply py-1 px-3 text-[#606266];
}

.tag__more,
.tag__add {
  @apply flex cursor-pointer bg-white;
}

.tag__others {
  @apply flex flex-wrap gap-[8px] mt-[18px];
}

.m-tag {
  @apply relative pr-[16px] max-w-[176px] truncate cursor-default;

  ::v-deep .el-tag__close {
    @apply absolute top-[4px] right-[1px] text-[#636363] font-medium;
  }
}
</style>
