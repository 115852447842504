<template>
  <BaseDialog
    v-model:visible="visible"
    title="顧客特質評分說明"
    width="576px"
    @close="$emit('close')"
  >
    <div class="content">
      <p>特質評分的分數以該顧客在此組織整體預約與消費數據為計算，若有多間分店會合併同一會員的消費數據，並跟此組織的所有會員表現比較。此數據一季計算一次，計算過後新增的會員要等到下次計算才會有資料。</p>
      <br>
      <p>貢獻度：代表該顧客對此組織的消費的貢獻程度，分數越高貢獻越大</p>
      <p>活躍程度：代表該顧客在此組織的互動狀況，包含預約、消費、參與活動等，分數越高越活躍</p>
      <p>消費潛力：代表該顧客可能消費的機會，分數越高未來越可能消費</p>
      <p>消費忠誠度：代表顧客在此組織整體消費的狀況，分數越高越忠誠</p>
      <p>消費分散度：代表顧客在此組織消費分佈的狀況，分數越高越分散</p>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'Member3660AnalysisDescriptionModal',
  components: { BaseDialog },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.content {
    @apply mb-[32px] h-[324px] overflow-y-auto p-[12px] text-sub;
    @apply border border-gray-40 rounded-[4px];
    @apply flex flex-col gap-[8px];
}
</style>
