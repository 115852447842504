import { admin2 } from '@/api/instance'

export const GetCustomFlowChangeLog = async ({
  shopId,
  start,
  limit,
  CustomFlowRecordId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customFlowChangeLog`,
    params: {
      start,
      limit,
      CustomFlowRecordId,
    },
  })
}

export const GetCustomFlowChangeLogCount = async ({
  shopId,
  CustomFlowRecordId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customFlowChangeLog/count`,
    params: {
      CustomFlowRecordId,
    },
  })
}
