export const noteCodeOptions = [
  {
    label: '後台使用',
    value: 'ADMIN_USE',
  },
  {
    label: '後台儲值',
    value: 'ADMIN_DEPOSIT',
  },
  {
    label: '銷售使用',
    value: 'SALE_USE',
  },
  {
    label: '銷售儲值',
    value: 'SALE_DEPOSIT',
  },
  {
    label: '取消銷售使用',
    value: 'CANCEL_SALE_USE',
  },
  {
    label: '取消銷售儲值',
    value: 'CANCEL_SALE_DEPOSIT',
  },
  {
    label: '電商使用',
    value: 'ECOMMERCE_USE',
  },
  {
    label: '取消電商使用',
    value: 'CANCEL_ECOMMERCE_USE',
  },
  {
    label: '抽獎活動儲值',
    value: 'AWARD_DEPOSIT',
  },
  {
    label: '訂金使用',
    value: 'APPOINTMENT_DEPOSIT_USE',
  },
  {
    label: '返還訂金',
    value: 'CANCEL_APPOINTMENT_DEPOSIT',
  },
  {
    label: '商城新增',
    value: 'MEMBER_STORE_DEPOSIT',
  },
  {
    label: '取消商城新增',
    value: 'CANCEL_MEMBER_STORE_DEPOSIT',
  },
  {
    label: '商城使用',
    value: 'MEMBER_STORE_USE',
  },
  {
    label: '預約結帳使用',
    value: 'APPOINTMENT_CHECKOUT_USE',
  },
  {
    label: '取消預約結帳使用',
    value: 'CANCEL_APPOINTMENT_CHECKOUT_USE',
  },
]

export const noteCodeSearchOptions = [
  {
    label: '使用',
    value: 'use',
  },
  {
    label: '儲值',
    value: 'deposit',
  },
]

export const exportFormatOptions = [
  {
    label: 'Excel',
    value: 'xlsx',
  },
]
