<template>
  <RouterLink class="link-item" :to="to">
    <slot />
  </RouterLink>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LinkItem',
  props: {
    to: { type: Object, default: () => ({ path: '/' }) },
  },
})
</script>

<style lang="postcss" scoped>
.link-item {
  @apply underline;
}
.link-item:hover {
  @apply text-primary-100;
}
</style>
