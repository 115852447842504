<template>
  <el-dialog
    title="選擇標籤"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <header class="flex flex-col" style="gap: 8px">
      <el-input
        v-model="search.name"
        placeholder="請輸入標籤名稱"
        clearable
        empty-text="暫無數據"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" />
      </el-input>
    </header>

    <main class="select-area">
      <div style="padding: 8px">
        <div class="border-b border-primary-100 mb-[12px]">
          <span class="inline text-[14px] text-primary-100 font-medium">
            自定義標籤
          </span>
        </div>
        <el-checkbox-group v-model="selected" class="item-group">
          <template v-for="item in chunkData[tableOptions.page - 1]">
            <div :key="item.id" class="truncate flex-1" style="padding-left: 15px">
              <el-checkbox :label="item.id" @change="onServiceCheck($event, item)">{{ item.name }}</el-checkbox>
            </div>
          </template>
        </el-checkbox-group>
      </div>
      <div>
        <Pagination
          :curPage.sync="tableOptions.page"
          :pageLimit="tableOptions.pageLimit"
          :total="flattenData.length"
        />
      </div>
    </main>

    <div class="flex justify-end items-center" style="padding-top: 26px">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" :disabled="!selected.length" @click="onSubmit">
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed, unref } from 'vue'
import { map, chunk, get, flattenDeep, filter, uniq, some } from 'lodash'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'TagSelector',
  props: {
    typeOptions: {
      type: Object,
      default: () => ({ none: { label: '暫無資料', value: 'none' } }),
    },
    data: { type: Array, default: () => [] },
    syncData: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    max: { type: [Number, null], default: null },
  },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { tableData, tableDataCount, tableOptions } = useTable()
    tableOptions.pageLimit = 10

    const search = reactive({
      name: null,
    })
    const selected = ref([])
    const activeList = ref([])

    const categorysData = computed(() => {
      return map(props.data, i => ({ id: i.id, name: i.name }))
    })

    const flattenData = computed(() => {
      let data = [...props.data]
      if (search.name) data = filter(data, (i) => i.name.includes(search.name))
      return data
    })

    const chunkData = computed(() => chunk(flattenData.value, tableOptions.pageLimit))

    const onSubmit = () => {
      emit('confirm', activeList.value)
      emit('close')
    }

    const onServiceCheck = (check, service) => {
      if (check) {
        activeList.value.push(service)
      } else if (!check) {
        activeList.value = filter(activeList.value, (item) => item.id !== service.id)
      }
    }

    onMounted(() => {
      tableData.value = props.data
      tableDataCount.value = props.data.length
      activeList.value = [...props.syncData]
      selected.value = [...map(props.syncData, 'id')]
    })

    return {
      get,
      flattenDeep,
      onSubmit,
      search,
      selected,
      tableDataCount,
      tableOptions,
      flattenData,
      chunkData,
      onServiceCheck,
      categorysData,
    }
  },
})
</script>

  <style scoped lang="postcss">
  .grid-container {
    @apply grid gap-[36px];
    @apply text-normal;
    grid-template-columns: 1fr 75px;
  }

  .item-group {
  @apply grid grid-flow-col gap-3;
  grid-template-rows: repeat(10, minmax(0, 1fr));
  > * {
    line-height: 14px;
  }
}

  ::v-deep .el-input {
    @apply w-full;
  }
  ::v-deep .el-select {
    @apply w-full;
  }
  ::v-deep .el-checkbox {
    @apply w-full;
  }
  ::v-deep .el-checkbox__label {
    @apply truncate;
    width: calc(100% - 15px);
  }
  .select-area {
    @apply border border-gray-40 mt-[12px] rounded-[4px];
  }
  .select-area-title {
    @apply p-[8px] text-base font-medium text-sub;
  }

  .divider {
    @apply mt-[8px] mb-[20px];
  }
  </style>
