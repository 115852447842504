<template>
  <div class="member-address-tab">
    <el-table v-loading="loading" :data="tableData">
      <EmptyBlock slot="empty" />
      <el-table-column prop="address" label="收件地點地址" align="center" />
      <el-table-column prop="isDefault" label="是否為預設" align="center">
        <template slot-scope="scope">
          <Tag :type="tagType(scope.row.isDefault)">{{ scope.row.isDefault ? '是' : '否' }}</Tag>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="建立時間" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="更新時間" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.updatedAt) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import tableMixin from '@/mixin/table'
import { GetUserMoreInfoAddress } from '@/api/member'
import dayjs from '@/lib/dayjs'
export default {
  name: 'MemberAddressTab',
  mixins: [tableMixin],
  props: {
    memberId: String,
  },
  data: () => ({
    tableData: [],
  }),
  async mounted () {
    this.getMemberAddress()
  },
  methods: {
    tagType (isDefault) {
      if (isDefault) return 'action'
      return 'info'
    },
    async getMemberAddress () {
      this.loading = true
      const [res, err] = await GetUserMoreInfoAddress({
        shopId: this.shop,
        memberId: this.memberId,
      })
      this.loading = false
      if (err) return this.$message.error(err)
      this.tableData = res.sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
