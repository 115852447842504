<template>
  <div>
    <FormInfo :gap="12">
      <FormInfoItem v-for="flow in displayFlow" :key="flow.id" :title="flow.name" width="150">
        <div class="flex items-center gap-[20px]">
          <ElInputWrapper>
            <el-select :value="curStatus[flow.id]" :loading="loading" @change="onStatusChange(flow, $event)">
              <el-option
                v-for="option in displayStatusOptions(flow.nodes)"
                :key="option.id"
                :value="option.id"
                :label="option.name"
              />
            </el-select>
          </ElInputWrapper>
          <el-button v-if="useCustomFlowFeature.changeLog" class="text-primary-100 underline" type="text" @click="onCheckRecordDetail(flow)">
            檢視異動紀錄
          </el-button>
        </div>
      </FormInfoItem>
    </FormInfo>

    <CustomFlowChangeLog
      v-if="show.changeLog"
      :flowName="selectFlow.name"
      :record="selectRecord"
      @close="show.changeLog = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, set, watchEffect } from 'vue'
import { UpdateCustomFlowRecordStatus } from '@/api/customFlow'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import { useShop } from '@/use/shop'
import { passValue } from '@/utils/helper'
import notifyMessage from '@/config/notifyMessage'
import ElInputWrapper from './ElInputWrapper.vue'
import CustomFlowChangeLog from '@/components/CustomFlowChangeLog.vue'
import { filter, find } from 'lodash'
import { useCustomFlow } from '@/use/useCustomFlow'

export default defineComponent({
  name: 'CustomFlowArea',
  components: { FormInfo, FormInfoItem, ElInputWrapper, CustomFlowChangeLog },
  props: {
    orderId: String,
    record: { type: Array, default: () => [] },
    customFlowConfig: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { useCustomFlowFeature } = useCustomFlow()
    const loading = ref(false)
    const curStatus = reactive({})
    const selectRecord = ref(null)
    const selectFlow = ref(null)
    const displayFlow = computed(() => {
      return filter(props.customFlowConfig, i => i.enable)
    })
    const show = reactive({
      changeLog: false,
    })
    const onStatusChange = async (flow, statusId) => {
      if (loading.value) return
      loading.value = true
      const payload = {
        shopId: shopId.value,
        orderId: props.orderId,
        customFlowId: flow.id,
        nodeId: statusId,
      }
      const [, err] = await UpdateCustomFlowRecordStatus(payload)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
    }

    const displayStatusOptions = (nodes) => {
      const options = passValue(nodes)
      options.unshift({ name: '–', id: null })
      return options
    }

    const onCheckRecordDetail = (flow) => {
      selectRecord.value = find(props.record, { CustomFlowId: flow.id })
      selectFlow.value = flow
      show.changeLog = true
    }

    watchEffect(() => {
      for (const flow of displayFlow.value) {
        set(curStatus, flow.id, null)
      }
      for (const record of props.record) {
        set(curStatus, record.CustomFlowId, record.nodeId)
      }
    })

    return {
      onStatusChange,
      loading,
      displayFlow,
      displayStatusOptions,
      curStatus,
      show,
      onCheckRecordDetail,
      selectRecord,
      selectFlow,
      useCustomFlowFeature,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
