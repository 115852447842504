<template>
  <div v-loading="loading" class="beauty-ai-360-analysis">
    <div>
      <Member360AnalysisChart
        :data="displayData"
      />
    </div>
    <div class="flex flex-col" style="gap: 48px">
      <div>
        <SectionTitle
          title="360度分析"
          btn="說明"
          @edit="modal.help = true"
        />
        <div class="flex items-center flex-wrap" style="gap: 32px; margin-top: 20px">
          <div v-for="item in displayData.score" :key="item.label" class="flex" style="gap: 12px">
            <p class="flex-shrink-0 font-medium">{{ item.label }}</p>
            <p class="text-primary-100 font-bold ">{{ item.value }}</p>
          </div>
        </div>
      </div>

      <div>
        <SectionTitle title="AI 顧客特質分析" hideBtn />
        <div class="flex items-start" style="margin-top: 20px; margin-bottom: 20px; gap: 8px">
          <p class="font-medium text-sub flex-shrink-0">AI 智能標籤：</p>
          <div v-if="aiTags.length" class="flex items-center flex-wrap" style="gap: 8px">
            <Tag v-for="tag in aiTags" :key="tag.id" type="action" size="small">
              {{ tag.name }}
            </Tag>
          </div>
          <div v-else>
            <p class="text-sub text-gray-60">未曾互動消費</p>
          </div>
        </div>

        <div class="text-sub text-gray-100">
          <p v-if="!aiTags.length">{{ displayData.description }}</p>

          <!-- 常來貴客 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_frequentCustomer'})">
            <p>顧客經常光顧並進行大額消費，對組織有很高的忠誠度。他頻繁參與活動，還常常預約和消費高價值產品。</p>
            <!-- <br> -->
            <p>建議提供個性化的優惠和忠誠獎勵計劃，以鞏固關係。可邀請顧客搶先體驗最新的美容技術和產品，增加專屬感、增強品牌認同感。</p>
          </div>
          <!-- 活躍熟客 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_activeCustomer'})">
            <p>顧客經常來店參與活動和預約，但消費金額相對較低。他們對品牌有較高的興趣和參與度，喜歡體驗新產品和服務。</p>
            <!-- <br> -->
            <p>建議提供新產品或新服務的免費試用機會，以促進更多消費。設計多次優惠方案，提高來訪次數和消費金額。</p>
          </div>
          <!-- 低調大戶 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_lowKeyBigSpender'})">
            <p>顧客雖然不常光顧，但一旦消費便是大額購買。可能對特定產品和服務有強烈偏好，且忠誠度較高。</p>
            <!-- <br> -->
            <p>建議根據其過往偏好，推薦高端產品組合，提升消費體驗。在生日或特殊節日提供專屬禮物或優惠券，增進客戶關係。</p>
          </div>
          <!-- 偶爾光顧 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_occasionalCustomer'})">
            <p>顧客偶爾來店消費，且消費金額不高。品牌對他的吸引力有限，參與度和忠誠度均低。</p>
            <!-- <br> -->
            <p>建議針對未來首次回訪提供特別優惠券，吸引其再次消費。提供特定時段的折扣或優惠服務，刺激非高峰期消費。於社群推廣活動，增加品牌曝光，吸引顧客參與。</p>
          </div>
          <!-- 潛力大戶 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_potentialBigSpender'})">
            <p>雖然近期未有消費，但以往有高活躍和高貢獻，潛在價值大。曾經是品牌的忠實顧客，對組織的產品和服務有深入的了解和信任。</p>
            <!-- <br> -->
            <p>建議設計專屬優惠計劃，鼓勵其重新開始光顧並增加消費。使用點數或會員等級，以培養他們的忠誠度。</p>
          </div>
          <!-- 潛力活躍客 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_potentialActiveCustomer'})">
            <p>顧客在過去活躍，但貢獻度相對較低，近期未有消費。他們對品牌活動有高度參與意願，但對產品的興趣和投入有限。</p>
            <!-- <br> -->
            <p>建議推出新服務的體驗活動，吸引他們再次參與並提高消費意願。根據過去的活躍情況，邀請參加小型體驗活動。提供靈活次堂票方案，鼓勵多次到訪及消費。</p>
          </div>
          <!-- 潛水大戶 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_deepDiveBigSpender'})">
            <p>雖然近期未有消費，但過去曾是大額消費者，偏好具體服務。購買決策較少但影響力大，是組織潛在的價值客戶。</p>
            <!-- <br> -->
            <p>建議定期追蹤通過電話或社群維繫關係，提供專屬折扣和活動信息。推薦高品質與熱門服務或商品，增加客戶滿意度。</p>
          </div>
          <!-- 潛水客 -->
          <div v-if="find(aiTags, {systemName: 'beautyAIMTag_deepDiveCustomer'})">
            <p>顧客在過去的消費頻率和貢獻度都很低，近期無消費行為。對品牌的關注度和忠誠度較低，可能因多種因素未參與消費。</p>
            <!-- <br> -->
            <p>建議提供低成本贈品或增值服務，吸引其再度光顧。聯繫維護定期通過電話或社交媒體推送信息，提升品牌存在感。</p>
          </div>
        </div>
      </div>
    </div>

    <Member3660AnalysisDescriptionModal
      v-if="modal.help"
      @close="modal.help = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import Member360AnalysisChart from './components/Member360AnalysisChart.vue'
import Member3660AnalysisDescriptionModal from './components/Member3660AnalysisDescriptionModal.vue'
import { GetBeautyAIMemberAnalysis360View } from '@/api/beautyAI'
import { useShop } from '@/use/shop'
import { get, filter, find } from 'lodash'

export default defineComponent({
  name: 'BeautyAI360Analysis',
  components: { SectionTitle, Member360AnalysisChart, Member3660AnalysisDescriptionModal },
  props: {
    member: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup (props) {
    const { shopId } = useShop()
    const memberAnalysisData = ref({})
    const loading = ref(true)
    const modal = reactive({
      help: false,
    })
    const aiTags = computed(() => {
      return filter(get(props.member, 'SystemMTag'), (tag) => tag.systemGroup === 'beautyAIMTag')
    })
    const displayData = computed(() => {
      const metricsData = get(memberAnalysisData.value, 'metrics', {})
      return {
        metricsData,
        score: [
          { label: '貢獻度', value: get(metricsData, 'contributionStar') || '-' },
          { label: '消費分散度', value: get(metricsData, 'dispersionStar') || '-' },
          { label: '活躍程度', value: get(metricsData, 'activityStar') || '-' },
          { label: '消費潛力', value: get(metricsData, 'potentialStar') || '-' },
          { label: '消費忠誠度', value: get(metricsData, 'loyaltyStar') || '-' },
        ],
        tags: [],
        description: '（尚未結算統計資料）',
      }
    })

    const refresh = async () => {
      loading.value = true
      const [res, err] = await GetBeautyAIMemberAnalysis360View({
        shopId: shopId.value,
        memberId: props.member.id,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      memberAnalysisData.value = res
    }

    onBeforeMount(async () => {
      refresh()
    })

    return { displayData, modal, memberAnalysisData, loading, aiTags, find }
  },
})
</script>

<style lang="postcss" scoped>
.beauty-ai-360-analysis {
  @apply bg-white rounded-[4px] p-[40px];
  @apply flex justify-between gap-[60px];
}
</style>
