import { customOrderStatusModulesConfig, orderCustomFlowRecordPathConfig } from '@/config/customOrderStatus'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { GetCustomFlow } from '@/api/customFlow'
import { ref, computed } from 'vue'
import { get, filter, find } from 'lodash'
import { usePermissions } from '@/use/permissions'

export const useCustomFlow = (scope) => {
  const { simpleFetch } = useFetch()
  const { shopId } = useShop()
  const { checkAction } = usePermissions()
  const customFlowConfig = ref([])
  const useCustomFlowFeature = computed(() => {
    return {
      config: checkAction('admin.customFlow.find'),
      record: checkAction('admin.customFlowRecord.find'),
      changeLog: checkAction('admin.customFlowChangeLog.find'),
    }
  })
  const displayCustomFlowConfig = computed(() => filter(customFlowConfig.value, i => i.enable))
  const getCustomFlowConfig = async () => {
    if (!useCustomFlowFeature.value.config) return
    await simpleFetch(GetCustomFlow, {
      shopId: shopId.value,
      scope: get(customOrderStatusModulesConfig, `${scope}.value`),
    }, (res) => {
      customFlowConfig.value = res
    })
  }

  const getExportCustomFlowFieldsData = ({ displayCustomFlowConfig, scopeType, itemData }) => {
    const row = {}
    if (displayCustomFlowConfig.length) {
      for (const customFlow of displayCustomFlowConfig) {
        const customFlowRecord = find(get(itemData, get(orderCustomFlowRecordPathConfig, (scopeType || scope))), { CustomFlowId: customFlow.id })
        row[customFlow.name] = customFlowRecord ? `${customFlowRecord.nodeName}` : '-'
      }
    }
    return row
  }

  return { getCustomFlowConfig, displayCustomFlowConfig, customFlowConfig, useCustomFlowFeature, getExportCustomFlowFieldsData }
}
