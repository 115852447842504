<template>
  <el-dialog
    title="編輯會員資訊"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-loading="!loaded" class="profile-tab">
      <div v-if="loaded">
        <el-form
          ref="form"
          :model="allFormData"
          label-position="top"
          class="form"
          :rules="formRules"
        >
          <el-form-item label="用戶狀態" prop="isBlock">
            <el-switch
              v-model="formData.isBlock"
              active-text="黑名單會員"
              inactive-text="正常會員"
            />
          </el-form-item>

          <el-form-item label="Line 綁定" prop="memberAuthLine">
            <div class="icon__vertical-align">
              <img
                v-if="formData.MemberAuthLine"
                src="@/assets/member/icon-line-auth-yes.svg"
                alt="Has Line auth"
              >
              <img
                v-else
                src="@/assets/member/icon-line-auth-no.svg"
                alt="Without Line auth"
              >
            </div>
            <div>
              <span
                v-if="formData.MemberAuthLine"
                class="cursor-pointer underline"
                style="color: var(--danger)"
                @click="showDeBindAuthLineDialog = true"
              >
                解除綁定
              </span>
            </div>
          </el-form-item>

          <el-form-item v-if="useMemberOpentix" label="OPENTIX 綁定">
            <div class="icon__vertical-align">
              <img
                v-if="opentixBinded"
                src="@/assets/member/icon-line-auth-yes.svg"
                alt="Has Line auth"
              >
              <img
                v-else
                src="@/assets/member/icon-line-auth-no.svg"
                alt="Without Line auth"
              >
            </div>
          </el-form-item>

          <el-form-item label="登入方式" prop="memberUserAuth">
            <div class="icon__vertical-align">
              <span
                v-if="
                  !formData.User.AuthLine &&
                    !formData.User.AuthFB &&
                    !formData.User.AuthPhoneAccount
                "
              >-</span>
              <img
                v-if="formData.User.AuthLine"
                src="@/assets/member/icon-auth-line.svg"
                alt="Line auth icon"
              >
              <img
                v-if="formData.User.AuthFB"
                src="@/assets/member/icon-auth-facebook.svg"
                alt="Facebook auth icon"
              >
              <img
                v-if="formData.User.AuthPhoneAccount"
                src="@/assets/member/icon-auth-phone.svg"
                alt="Phone Account auth icon"
              >
            </div>
          </el-form-item>

          <MemberLevelField :formData.sync="formData" :member="member" />

          <!-- <el-form-item label="會員級別" prop="level">
            <el-select v-model="formData.level">
              <el-option
                v-for="(item, index) in levelList"
                :key="index"
                :label="item.name"
                :value="item.level"
              />
            </el-select>
          </el-form-item> -->

          <el-form-item label="手機號碼" prop="phone">
            <div>
              <el-input :value="formData.phone" disabled />
              <div
                v-if="!formData.phone"
                class="cursor-pointer underline"
                style="color: var(--primary-100)"
                @click="showBindPhoneDialog = true"
              >
                綁定
              </div>
              <div
                v-if="formData.phone"
                class="cursor-pointer underline"
                style="color: var(--danger)"
                @click="showDeBindPhoneDialog = true"
              >
                解除綁定
              </div>
            </div>
          </el-form-item>

          <!-- 基本欄位 -->
          <el-form-item
            v-for="field in baseUserSetting"
            :key="field.key"
            :label="field.name"
            :prop="field.key"
            :rules="getBaseSettingRules[field.key]"
          >
            <component
              :is="getBaseFieldComponent(field.type).is"
              v-model="formData[field.key]"
              :name="field.key"
              :maxlength="30"
              show-word-limit
              :type="getBaseFieldComponent(field.type).nativeType || 'text'"
              :disabled="!field.adminEdit"
              :disabledAll="!field.adminEdit"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :hideLabel="true"
              :year.sync="formData.birthday.year"
              :month.sync="formData.birthday.month"
              :date.sync="formData.birthday.date"
              :city.sync="formData.address.city"
              :area.sync="formData.address.area"
              :detail.sync="formData.address.detail"
              placeholder="尚未填寫"
              :required="field.required"
            >
              <el-option
                v-for="item in getBaseFieldComponent(field.type).options"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </component>
          </el-form-item>

          <!-- 自定義欄位 -->
          <el-form-item
            v-for="field in customUserSetting"
            v-show="
              field.type !== 'companyDirectoryFirmTaxId' ||
                (showCompany && field.type === 'companyDirectoryFirmTaxId')
            "
            :key="field.key"
            :label="field.name"
            :prop="field.key"
            :rules="getCustomSettingRules[field.key]"
          >
            <component
              :is="getCustomFieldComponent(field.type).is"
              v-model="formDataCustom[field.key]"
              :name="field.key"
              :type="getCustomFieldComponent(field.type).nativeType || 'text'"
              :disabled="!field.adminEdit"
              :multiple="getCustomFieldComponent(field.type).multiple"
              placeholder="尚未填寫"
              value-key="id"
              :autosize="{ minRows: 2, maxRows: 5 }"
              :targetMemberId="member.id"
              :fileId.sync="formDataCustom[field.key]"
            >
              <el-option
                v-for="item in field.enum || customeEnum[field.type]"
                :key="item.id || item"
                :label="item.label || item"
                :value="item.value || item"
              />
            </component>
          </el-form-item>
        </el-form>

        <div slot="footer" class="flex justify-end">
          <el-button plain @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">儲存</el-button>
        </div>
      </div>
      <!-- ANCHOR 解除/綁定手機 Dialog -->
      <DeBindPhoneWarningDialog
        v-loading="isLoading"
        :show.sync="showDeBindPhoneDialog"
        :member="member"
        @debind="onDebindPhone"
      />
      <BindPhoneDialog
        v-loading="isLoading"
        :show.sync="showBindPhoneDialog"
        :member="member"
        @bind="onBindPhone"
      />
      <DebindAuthLineDialog
        v-loading="isLoading"
        :member="member"
        :show.sync="showDeBindAuthLineDialog"
        @debind=";(showDeBindAuthLineDialog = false), $emit('refresh')"
      />
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/lib/dayjs'
import AddressInput from '@/components/Input/AddressInput'
import BirthDayInput from '@/components/Input/BirthDayInput.vue'
import BindPhoneDialog from './BindPhoneDialog.vue'
import DeBindPhoneWarningDialog from './DeBindPhoneWarningDialog.vue'
import DebindAuthLineDialog from './DeBindAuthLineDialog.vue'
import MemberLevelField from './MemberLevelField.vue'
import RestrictedImageUpload from '@/components/RestrictedImageUpload'
// api
import { SimpleFindCompanyContactMenu } from '@/api/company/contactMenu'
import { UpdateMember } from '@/api/member'
import { DeleteAuthPhone, UpdateAuthPhone } from '@/api/auth'
// utils
import {
  phoneRules,
  noEmptyRules,
  emailRulesNoRequired,
  maxRules,
} from '@/validation/index'
import formUtils from '@/utils/form'
import AddressModel from '@/utils/address'
import { genderOptions } from '@/config/userInfoOptions'
import cities from '@/assets/data/cities'
import { get, find } from 'lodash'
import { useCompany } from '@/use/company'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MemberProfileEdit',
  components: {
    RestrictedImageUpload,
    BindPhoneDialog,
    DeBindPhoneWarningDialog,
    DebindAuthLineDialog,
    AddressInput,
    BirthDayInput,
    MemberLevelField,
  },
  props: {
    member: { type: Object, required: true, default: () => {} },
    levelList: { type: Array, required: true, default: () => [] },
    baseUserSetting: { type: Array, required: true, default: () => [] },
    customUserSetting: { type: Array, required: true, default: () => [] },
    opentixBinded: Boolean,
    useMemberOpentix: Boolean,
  },

  setup () {
    const { useCompanyContactMenu, getShopContactMenuInfo, orgTaxId } =
      useCompany()

    return {
      orgTaxId,
      getShopContactMenuInfo,
      useCompanyContactMenu,
    }
  },

  data: () => ({
    loaded: false,
    isLoading: false,
    showDeBindPhoneDialog: false,
    showBindPhoneDialog: false,
    showDeBindAuthLineDialog: false,
    formData: {
      MemberAuthLine: false,
      isBlock: false,
      level: '',
      levelExpiredAt: null,
      levelNote: null,
      memberLevelEnable: true,
      User: {},
      custom: {},
      isLevelRemainFulfilled: false,
    },
    formDataCustom: {}, // 自定義欄位資料
    customeEnum: {},
    // ANCHOR data rules
    baseRequiredRules: {
      name: [noEmptyRules('請輸入姓名')],
      email: [noEmptyRules('請輸入信箱')],
      gender: [noEmptyRules('請選擇性別')],
      birthday: [noEmptyRules('請選擇生日')],
      'birthday.year': [noEmptyRules('請選擇')],
      'birthday.month': [noEmptyRules('請選擇')],
      'birthday.date': [noEmptyRules('請選擇')],
      address: [noEmptyRules('請輸入地址')],
    },
    baseAdvanceRules: {
      name: [maxRules(30)],
      email: [emailRulesNoRequired()],
      phone: [phoneRules()],
      gender: [],
      birthday: [],
      address: [],
    },
    // 定義固定欄位元件
    baseFieldsInput: {
      address: {
        is: AddressInput,
      },
      birthday: {
        is: 'BirthDayInput',
        placeholder: '選擇日期',
        format: 'yyyy-MM-dd',
        'value-format': 'yyyy-MM-dd',
        nativeType: 'date',
      },
      gender: {
        is: 'el-select',
        options: genderOptions,
      },
    },
    // 定義自訂欄位元件
    customFieldsInput: {
      text: {
        is: 'el-input',
      },
      richtext: {
        is: 'el-input',
        nativeType: 'textarea',
      },
      radio: {
        is: 'el-select',
      },
      checkbox: {
        is: 'el-select',
        multiple: true,
      },
      companyDirectoryFirmTaxId: {
        is: 'el-select',
        multiple: false,
      },
      restrictedImage: {
        is: 'RestrictedImageUpload',
      },
    },
  }),

  computed: {
    ...mapGetters(['orgId', 'shop', 'userPlanFeature', 'userFeatures']),
    shop () {
      return this.$store.getters.shop
    },
    getBaseFieldComponent () {
      return (fieldType) =>
        this.baseFieldsInput[fieldType] || { is: 'el-input' }
    },
    getCustomFieldComponent () {
      return (fieldType) =>
        this.customFieldsInput[fieldType] || { is: 'el-input' }
    },
    // ANCHOR Dynamic rules
    getBaseSettingRules () {
      return this.baseUserSetting.reduce((rules, field) => {
        if (!field.adminEdit) {
          return rules
        }
        if (field.required) {
          rules[field.key] = this.baseRequiredRules[field.key].concat(
            this.baseAdvanceRules[field.key],
          )
          return rules
        }
        rules[field.key] = this.baseAdvanceRules[field.key]
        return rules
      }, {})
    },
    getCustomSettingRules () {
      return this.customUserSetting.reduce((rules, field) => {
        if (!field.adminEdit) {
          return rules
        }
        if (field.required) {
          rules[field.key] = [noEmptyRules('必填')]
        }
        return rules
      }, {})
    },
    compactFormData () {
      const formData = { ...this.formData }
      if (!formData.level) {
        formData.level = 0
      }
      formData.birthday = this.compactBirthday(formData)
      formData.address = this.compactAddress(formData)
      return formData
    },
    allFormData () {
      return { ...this.compactFormData, ...this.formDataCustom }
    },
    showCompany () {
      return this.useCompanyContactMenu && this.orgTaxId(this.customUserSetting)
    },
    formRules () {
      const rule = {
        level: [noEmptyRules()],
      }
      if (!this.formData.forever) {
        rule.levelExpiredAt = [noEmptyRules()]
      }
      return rule
    },
  },

  watch: {
    member: {
      handler () {
        this.syncForm()
      },
      deep: true,
    },
  },

  async mounted () {
    this.syncForm()
    await this.getShopContactMenuInfo({ shopId: this.shop })
    if (!this.useCompanyContactMenu || !this.orgTaxId(this.customUserSetting)) {
      return
    }
    this.simpleFindCompanyContactMenu()
  },

  methods: {
    async simpleFindCompanyContactMenu () {
      let start = 0
      const limit = 10
      const data = []
      let temp = []
      const [res, err] = await SimpleFindCompanyContactMenu({
        shopId: this.shop,
        start,
        limit,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      start += res.length
      temp = res
      data.push(...res)

      while (temp.length === limit) {
        const [res, err] = await SimpleFindCompanyContactMenu({
          shopId: this.shop,
          start,
          limit,
        })
        if (err) {
          this.$message.error(err)
          return
        }
        start += res.length
        temp = res
        data.push(...res)
      }

      this.$set(
        this.customeEnum,
        'companyDirectoryFirmTaxId',
        data.map((i) => ({
          label: i.name,
          id: i.id,
          value: i.taxId,
        })),
      )
    },
    compactBirthday (data) {
      const year = get(data, 'birthday.year')
      const month = get(data, 'birthday.month')
      const date = get(data, 'birthday.date')
      if (!year || !month || !date) return null
      return dayjs(`${year}-${month}-${date}`).toDate()
    },
    compactAddress (data) {
      const city = get(data, 'address.city')
      const area = get(data, 'address.area')
      const detail = get(data, 'address.detail')
      return city + area + detail
    },
    async confirm () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const success = await this.updateMember()
      if (!success) return
      this.$emit('refresh')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },

    async updateMember () {
      try {
        await UpdateMember({
          shopId: this.shop,
          ...this.compactFormData,
          phone: undefined,
          gender: this.formData.gender || null,
          custom: this.formDataCustom,
        })
        this.$message.success('更新成功')
        return true
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
        return false
      }
    },

    syncForm () {
      this.formData = { ...this.member }

      if (this.formData.birthday) {
        const birthday = dayjs(this.formData.birthday).format('YYYY-MM-DD').split('-')
        this.$set(this.formData, 'birthday', {})
        this.$set(this.formData.birthday, 'year', Number(birthday[0]))
        this.$set(this.formData.birthday, 'month', Number(birthday[1]))
        this.$set(this.formData.birthday, 'date', Number(birthday[2]))
      }
      if (!this.formData.birthday) {
        this.$set(this.formData, 'birthday', {
          year: '',
          month: '',
          date: '',
        })
      }

      if (this.formData.address) {
        const addressParser = new AddressModel(cities)
        const address = addressParser.parse(this.formData.address)
        this.$set(this.formData, 'address', {})
        this.$set(this.formData.address, 'city', address.city)
        this.$set(this.formData.address, 'area', address.area)
        this.$set(this.formData.address, 'detail', address.address)
      }
      if (!this.formData.address) {
        this.$set(this.formData, 'address', {})
        this.$set(this.formData.address, 'city', '')
        this.$set(this.formData.address, 'area', '')
        this.$set(this.formData.address, 'detail', '')
      }
      this.formDataCustom = { ...this.member.custom }
      this.loaded = true
    },
    // ANCHOR onDebindPhone
    async onDebindPhone () {
      this.isLoading = true
      const [, err] = await DeleteAuthPhone({
        shopId: this.shop,
        id: this.member.id,
      })
      this.isLoading = false

      if (err) return this.$message.error(err)

      this.formData.phone = ''
      this.$message.success('解除綁定成功！')
      this.showDeBindPhoneDialog = false
      this.$emit('refresh')
    },
    // ANCHOR onDebindPhone
    async onBindPhone ({ phone }) {
      this.isLoading = true
      const [, err] = await UpdateAuthPhone({
        shopId: this.shop,
        id: this.member.id,
        phone,
      })
      this.isLoading = false

      if (err) return this.$message.error(err)

      this.formData.phone = phone
      this.$message.success('更新成功！')
      this.showBindPhoneDialog = false
      this.$emit('refresh')
    },
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-select .el-input {
  @apply h-full;
}
::v-deep .el-tag--info {
  @apply h-full break-all;
  white-space: break-spaces;
}
::v-deep .el-select__tags {
  @apply flex flex-wrap;
}

.bind-phone-btn {
  @apply cursor-pointer text-left;
  @apply absolute left-[300px] top-0;
}

.icon__vertical-align {
  @apply flex gap-x-[4px];
  @apply mt-[10px];
}

.el-date-editor {
  @apply w-full;
}
</style>
