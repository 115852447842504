export const customOrderStatusModulesConfig = {
  appointment: { label: '預約', value: 'appointment' },
  pointExchange: { label: '點數兌換', value: 'pointExchange' },
  memberStore: { label: '會員商城', value: 'memberStore' },
  ecommerce: { label: '電商', value: 'ecommerce' },
}

export const orderCustomFlowRecordPathConfig = {
  appointment: 'AppointmentOrder.CustomFlowRecords',
  pointExchange: 'CustomFlowRecords',
  memberStore: 'CustomFlowRecords',
  ecommerce: 'CustomFlowRecords',
}
