<template>
  <el-drawer
    title="流程異動記錄"
    :visible="true"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="classTicket-record-detail"
    @close="$emit('close')"
  >
    <div v-loading="loading" class="content-wrapper">
      <div class="flex gap-[12px] text-[20px] pb-[20px]">
        <p class="font-bold">流程名稱</p>
        <p>{{ flowName }}</p>
      </div>
      <el-steps
        v-if="displayData.length >= 1"
        direction="vertical"
        :active="displayData.length"
      >
        <el-step
          v-for="log in displayData"
          :key="log.id"
          :description="log.time"
        >
          <template slot="description">
            <div class="text-sub flex flex-col gap-[4px]" style="padding-bottom: 70px">
              <p class="font-medium text-gray-100">變更為 {{ log.title }}</p>
              <p class="text-gray-60">{{ log.dateTime }}</p>
              <p class="text-gray-60">{{ log.username }}</p>
            </div>
          </template>
        </el-step>
      </el-steps>
      <EmptyBlock v-else />
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { GetCustomFlowChangeLog } from '@/api/customFlowChangeLog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { formatDate } from '@/utils/date'
import { map, get } from 'lodash'

export default defineComponent({
  name: 'CustomFlowChangeLog',
  components: { EmptyBlock },
  props: {
    flowName: String,
    record: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const historyData = ref([])
    const loading = ref(true)

    const displayData = computed(() => {
      return map(historyData.value, (item) => {
        return {
          title: item.targetName,
          dateTime: formatDate(item.createdAt),
          username: get(item.Creator, 'name'),
        }
      })
    })
    onBeforeMount(async () => {
      if (props.record.id) {
        await fetchAll(GetCustomFlowChangeLog,
          { shopId: shopId.value, CustomFlowRecordId: props.record.id },
          (res) => {
            historyData.value = res
          },
        )
      }
      loading.value = false
    })
    return { historyData, displayData, loading }
  },
})
</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply px-[20px];
}
.dateTime {
  @apply text-gray-60 text-sub pb-[8px];
}

::v-deep .el-step__description {
  @apply pr-0 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}
</style>
