import { admin2 } from '@/api/instance'

export const FindDepositPayment = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentDepositPaymentConfig/${id}`,
  })
}

export const GetDepositPayment = async ({
  shopId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentDepositPaymentConfig`,
    params: {
      start,
      limit,
    },
  })
}

export const GetDepositPaymentCount = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentDepositPaymentConfig/count`,
  })
}

export const CreateDepositPayment = async ({
  shopId,
  type,
  enable,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentDepositPaymentConfig`,
    data: {
      type,
      enable,
      config,
    },
  })
}

export const UpdateDepositPayment = async ({
  shopId,
  id,
  enable,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentDepositPaymentConfig/${id}`,
    data: {
      enable,
    },
  })
}

export const DeleteDepositPayment = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/appointmentDepositPaymentConfig/${id}`,
  })
}

export const UpdateDepositConfig = async ({
  shopId,
  enable,
  enableClassTicketCredit,
  feeModel,
  feeModelServiceCountPrice,
  feeModelTotalPriceRatio,

  enableIgnoreService,
  ignoreServices,
  enableSpecifyService,
  specifyServices,

  enableIgnoreServiceAttach,
  ignoreServiceAttaches,
  enableSpecifyServiceAttach,
  specifyServiceAttaches,

  enableIgnoreMTag,
  ignoreMTags,
  enableSpecifyMTag,
  specifyMTags,

  enableSpecifyTimeRange,
  specifyTimeRangeStart,
  specifyTimeRangeEnd,

  paymentDeadlineMinute,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentDepositConfig`,
    data: {
      enable,
      enableClassTicketCredit,
      feeModel,
      feeModelServiceCountPrice,
      feeModelTotalPriceRatio,

      enableIgnoreService,
      ignoreServices,
      enableSpecifyService,
      specifyServices,

      enableIgnoreServiceAttach,
      ignoreServiceAttaches,
      enableSpecifyServiceAttach,
      specifyServiceAttaches,

      enableIgnoreMTag,
      ignoreMTags,
      enableSpecifyMTag,
      specifyMTags,

      enableSpecifyTimeRange,
      specifyTimeRangeStart,
      specifyTimeRangeEnd,

      paymentDeadlineMinute,
    },
  })
}

export const GetDepositConfig = async ({
  shopId,
}) => {
  return await admin2({
    methods: 'POST',
    url: `/${shopId}/appointmentDepositConfig`,
  })
}
